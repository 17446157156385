.container{
    margin:0;
    padding:0;
    display: flex;
   /*border: 2px solid #6c4ade;*/
    position: relative;
    flex-direction: column;
    justify-content: center;
    height: calc( 100vh - 10px);
    width: calc( 100vw - 10px);
    align-items: center;
    justify-content: flex-start;



     /* border-radius: 12px;*/

      position: relative;
      overflow: hidden;

   /* border: 4px solid red;*/
}


.navbar_container{
    margin:0;
    padding:0;
    display: flex;
    width:100%;
    height:64px;
    border: 1px solid blue;
    position: relative;
    
    background: linear-gradient(
      108.63deg,
      rgba(101, 101, 209, 0.6) 0%,
      rgba(24, 61, 186, 0.3) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
     /* border-radius: 12px;*/

      position: relative;
      overflow: hidden;
}

.editor_container{
    margin:0;
    padding:0;
    display: flex;
    /*border: 2px solid black;*/
    position: relative;
    width:100%;
    height:calc( 100% - 64px );
    
    background: linear-gradient(
      108.63deg,
      rgba(101, 101, 209, 0.6) 0%,
      rgba(24, 61, 186, 0.3) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
    /*  border-radius: 12px;*/

      position: relative;
      overflow: hidden;
}