.landing,
.landing * {
  box-sizing: border-box;
}
.landing {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-left: calc(100% - 90%);
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}
.background {
  flex-shrink: 0;
  width: 1440px;
  height: 1061px;
  position: relative;
  overflow: visible;
}
.frame_266 {
  background: #ffffff;
  padding: 100px 56px 80px 56px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.container {
  padding: 0px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 1328px;
  position: relative;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.heading_and_supporting_text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 768px;
  position: relative;
}
.heading_and_subheading {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.subheading {
  color: #6c4ade;
  text-align: center;
  font: 700 16px/24px "ProductSans-Bold", sans-serif;
  position: relative;
  align-self: stretch;
}
.heading {
  color: var(--gray-900, #101828);
  text-align: center;
  font: 700 36px/44px "ProductSans-Bold", sans-serif;
  position: relative;
  align-self: stretch;
}
.supporting_text {
  color: var(--gray-500, #667085);
  text-align: center;
  font: 400 20px/30px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.benefits {
  display: flex;
  flex-direction: column;
  gap: 56px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.benefit {
  display: flex;
  flex-direction: row;
  gap: 144px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 1328px;
  position: relative;
}
.content2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 528px;
  position: relative;
}
.icon_and_text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.featured_icon {
  background: var(--primary-100, #f4ebff);
  border-radius: 28px;
  border-style: solid;
  border-color: var(--primary-50, #f9f5ff);
  border-width: 8px;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  position: relative;
}
.zap {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 12px;
  top: 12.5px;
  overflow: visible;
}
.heading_and_supporting_text2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.heading2 {
  color: var(--gray-900, #101828);
  text-align: left;
  font: 700 30px/38px "ProductSans-Bold", sans-serif;
  position: relative;
  align-self: stretch;
}
.supporting_text2 {
  color: var(--gray-500, #667085);
  text-align: left;
  font: 400 18px/28px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.content3 {
  flex-shrink: 0;
  width: 656px;
  height: 573px;
  position: static;
}
.rectangle_51 {
  background: #ebeaff;
  border-radius: 24px;
  width: 656px;
  height: 573px;
  position: absolute;
  left: 672px;
  top: 0px;
}
.rectangle_55 {
  border-radius: 14px;
  width: 350.78px;
  height: 227.37px;
  position: absolute;
  left: 953.27px;
  top: 57px;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  transform-origin: 0 0;
  transform: rotate(12deg) scale(1, 1);
}
.rectangle_53 {
  border-radius: 14px;
  width: 415px;
  height: 269px;
  position: absolute;
  left: 714px;
  top: 147.72px;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  transform-origin: 0 0;
  transform: rotate(-3deg) scale(1, 1);
}
.rectangle_54 {
  border-radius: 14px;
  width: 415px;
  height: 269px;
  position: absolute;
  left: 889.76px;
  top: 238px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08),
    10px -5px 12px 0px rgba(0, 0, 0, 0.02);
  transform-origin: 0 0;
  transform: rotate(4deg) scale(1, 1);
}
.messsage {
  width: 351.13px;
  height: 87.81px;
  position: static;
}
.frame_17 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  left: calc(50% - -20px);
  bottom: 77.66%;
  top: 12.26%;
  height: 10.08%;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  transform-origin: 0 0;
  transform: rotate(-5deg) scale(1, 1);
}
.message {
  flex-shrink: 0;
  width: 307.84px;
  height: 57.76px;
  position: static;
}
.frame_7 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 57.76px;
  position: absolute;
  left: 21.84px;
  top: 0px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(-1, 1);
}
.rectangle_8 {
  background: #6c4ade;
  border-radius: 0px 32.76px 0px 0px;
  flex-shrink: 0;
  width: 21.84px;
  height: 27.3px;
  position: relative;
}
.rectangle_10 {
  background: #6c4ade;
  flex: 1;
  width: 21.84px;
  position: relative;
}
.rectangle_11 {
  background: #6c4ade;
  border-radius: 0px 0px 32.76px 0px;
  flex-shrink: 0;
  width: 21.84px;
  height: 27.3px;
  position: relative;
}
.frame_6 {
  background: #6c4ade;
  padding: 16.38px 0px 16.38px 0px;
  display: flex;
  flex-direction: column;
  gap: 10.92px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 21.84px;
  top: 0px;
}
.frame_5 {
  display: flex;
  flex-direction: column;
  gap: 10.92px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.generate_corporative_website {
  color: #ffffff;
  text-align: left;
  font: 400 21.84px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_8 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  height: 57.67px;
  position: absolute;
  left: 1030.1px;
  top: 40px;
  transform: translate(-39.43px, 0px);
  overflow: visible;
}
.messsage2 {
  width: 257.6px;
  height: 79.61px;
  position: static;
}
.frame_172 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  left: calc(50% - -348.02px);
  bottom: 25%;
  top: 64.92%;
  height: 10.08%;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  transform-origin: 0 0;
  transform: rotate(11deg) scale(1, 1);
}
.message2 {
  flex-shrink: 0;
  width: 215.84px;
  height: 57.76px;
  position: static;
}
.make_it_more_stylish {
  color: #ffffff;
  text-align: left;
  font: 400 21.84px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.frame_82 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  height: 57.67px;
  position: absolute;
  left: 1260.89px;
  top: 420.4px;
  transform: translate(-49.86px, -7.55px);
  overflow: visible;
}
.benefit2 {
  display: flex;
  flex-direction: row;
  gap: 144px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.img {
  flex-shrink: 0;
  width: 656px;
  height: 573px;
  position: relative;
  overflow: hidden;
}
.content4 {
  position: absolute;
  inset: 0;
}
.rectangle_512 {
  background: #ebeaff;
  border-radius: 24px;
  width: 656px;
  height: 573px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.created {
  background: #f8f8f8;
  border-radius: 12.84px;
  width: 371px;
  height: 336px;
  position: absolute;
  left: 262px;
  top: 78px;
  box-shadow: 0px 4.72px 6.3px -1.57px rgba(11, 93, 153, 0.16),
    0px 2.36px 2.36px -0.79px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.header {
  background: #ffffff;
  padding: 6.3px 15.74px 6.3px 15.74px;
  display: flex;
  flex-direction: row;
  gap: 386.88px;
  align-items: center;
  justify-content: flex-end;
  width: 551px;
  position: absolute;
  left: -180px;
  top: 0px;
  box-shadow: 0px 0.79px 4.72px 0px rgba(1, 19, 189, 0.04);
}
.action {
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_22 {
  background: rgba(69, 65, 229, 0.09);
  border-radius: 3.15px;
  padding: 4.72px 6.3px 4.72px 6.3px;
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.eye {
  flex-shrink: 0;
  width: 6.3px;
  height: 6.3px;
  position: relative;
  overflow: visible;
}
.preview {
  color: #000000;
  text-align: left;
  font: 400 6.3px "Helvetica-Regular", sans-serif;
  position: relative;
}
.frame_24 {
  background: rgba(69, 65, 229, 0.09);
  border-radius: 3.15px;
  padding: 4.72px 6.3px 4.72px 6.3px;
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.download {
  flex-shrink: 0;
  width: 6.3px;
  height: 6.3px;
  position: relative;
  overflow: visible;
}
.save {
  color: #000000;
  text-align: left;
  font: 400 6.3px "Helvetica-Regular", sans-serif;
  position: relative;
}
.frame_23 {
  background: #4541e5;
  border-radius: 3.15px;
  padding: 4.72px 6.3px 4.72px 6.3px;
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.publish {
  color: #ffffff;
  text-align: left;
  font: 400 6.3px "Helvetica-Regular", sans-serif;
  position: relative;
}
.button_group {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 8.13px;
  top: 38.57px;
  box-shadow: 0px 0.39px 0.79px 0px rgba(16, 24, 40, 0.05);
}
.button_group_base {
  background: #f9fafb;
  border-radius: 3.15px 0px 0px 3.15px;
  border-style: solid;
  border-color: #d0d5dd;
  border-width: 0.39px;
  padding: 3.94px 6.3px 3.94px 6.3px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.text {
  color: #344054;
  text-align: left;
  font: 500 5.51px/7.87px "Inter-Medium", sans-serif;
  position: relative;
}
.button_group_base2 {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.top_line {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  height: 0.39px;
  position: relative;
}
.content5 {
  padding: 3.54px 6.3px 3.54px 6.3px;
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.bottom_line {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  height: 0.39px;
  position: relative;
}
.divider {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  width: 0.39px;
  position: relative;
}
.button_group_base3 {
  background: #ffffff;
  border-radius: 0px 3.15px 3.15px 0px;
  border-style: solid;
  border-color: #d0d5dd;
  border-width: 0.39px;
  padding: 3.94px 4.72px 3.94px 4.72px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.text2 {
  color: #4541e5;
  text-align: left;
  font: 500 5.51px/7.87px "Inter-Medium", sans-serif;
  position: absolute;
  left: 268.67px;
  top: 42.51px;
}
.chat {
  background: #ffffff;
  border-style: solid;
  border-color: #d2d2d2;
  border-width: 0px 0.39px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 180.26px;
  height: 335.72px;
  position: absolute;
  left: -180px;
  top: 0px;
  box-shadow: 0px 0px 9.45px 0.79px rgba(0, 0, 0, 0.08),
    0px 1.57px 6.3px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.frame_27 {
  background: rgba(255, 255, 255, 0.85);
  border-style: solid;
  border-color: #d2d2d2;
  border-width: 0px 0px 0.39px 0px;
  padding: 6.3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 29.12px;
  position: relative;
  backdrop-filter: blur(3.15px);
}
.frame_28 {
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_2 {
  background: radial-gradient(
      closest-side,
      rgba(108, 74, 222, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 12.59px;
  height: 12.59px;
  position: relative;
}
.ai_builder {
  color: #000000;
  text-align: left;
  font: 700 6.3px "Helvetica-Bold", sans-serif;
  position: relative;
}
.remix_icons_line_system_question_line {
  flex-shrink: 0;
  width: 9.45px;
  height: 9.45px;
  position: relative;
  overflow: hidden;
}
.group {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.frame_19 {
  padding: 0px 6.3px 6.3px 6.3px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.frame_43 {
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.message_bubble {
  border-radius: 9.45px 0.79px 9.45px 9.45px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_59 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0.39px 0.39px 3.15px 0px rgba(0, 0, 0, 0.18);
}
.frame_83 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_62 {
  background: var(--sufrace-surface-2, #fafafa);
  padding: 4.72px 0px 4.72px 0px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width: 196.79px;
  position: relative;
}
.frame_52 {
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width: 196.79px;
  position: relative;
}
.write_your_message_here_3 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 6.3px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_72 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.rectangle_84 {
  background: var(--sufrace-surface-2, #fafafa);
  border-radius: 0px 3.15px 0px 0px;
  flex-shrink: 0;
  width: 6.3px;
  height: 7.87px;
  position: relative;
}
.rectangle_114 {
  background: var(--sufrace-surface-2, #fafafa);
  border-radius: 0px 0px 3.15px 0px;
  flex-shrink: 0;
  width: 6.3px;
  height: 7.87px;
  position: relative;
}
.frame_44 {
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.message_bubble2 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_61 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0.39px 0.39px 3.15px 0px rgba(0, 0, 0, 0.18);
}
.frame_73 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(-1, 1);
}
.rectangle_85 {
  background: #cfd2ff;
  border-radius: 0px 3.15px 0px 0px;
  flex-shrink: 0;
  width: 6.3px;
  height: 7.87px;
  position: relative;
}
.rectangle_104 {
  background: #cfd2ff;
  flex: 1;
  width: 6.3px;
  position: relative;
}
.rectangle_115 {
  background: #cfd2ff;
  border-radius: 0px 0px 3.15px 0px;
  flex-shrink: 0;
  width: 6.3px;
  height: 7.87px;
  position: relative;
}
.frame_63 {
  background: #cfd2ff;
  padding: 4.72px 0px 4.72px 0px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width: 196.79px;
  position: relative;
  overflow: hidden;
}
.frame_9 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_45 {
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_60 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0.39px 0.39px 3.15px 0px rgba(0, 0, 0, 0.18);
}
.frame_92 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_64 {
  background: var(--sufrace-surface-2, #fafafa);
  padding: 4.72px 0px 4.72px 0px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width: 118.07px;
  position: relative;
}
.frame_53 {
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width: 118.07px;
  position: relative;
}
.frame_14 {
  padding: 0px 5.12px 6.3px 5.12px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_71 {
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_11 {
  background: #f3f3f3;
  border-radius: 3.15px;
  padding: 6.3px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 37.78px;
  position: relative;
  overflow: hidden;
}
.frame_522 {
  background: #ffffff;
  border-radius: 3.15px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 25.19px;
  position: relative;
  box-shadow: 0px 0px 6.3px 1.57px rgba(0, 0, 0, 0.16),
    0px 1.57px 1.57px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}
.frame_46 {
  background: #ecccc4;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_47 {
  background: #db9390;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_48 {
  background: #fbe7e8;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_49 {
  background: #b5d6ae;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_50 {
  background: #e58a99;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_173 {
  background: #f3f3f3;
  border-radius: 3.15px;
  padding: 6.3px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 37.78px;
  position: relative;
  overflow: hidden;
}
.frame_462 {
  background: #b0cef1;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_472 {
  background: #3a494e;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_482 {
  background: #000000;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_492 {
  background: #85a0b5;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_502 {
  background: #5e7080;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_722 {
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_12 {
  background: #f3f3f3;
  border-radius: 3.15px;
  padding: 6.3px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 37.78px;
  position: relative;
  overflow: hidden;
}
.frame_463 {
  background: #fefcf3;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_473 {
  background: #cbc5ba;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_483 {
  background: #3f3d39;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_493 {
  background: #252422;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_503 {
  background: #da6639;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_16 {
  background: #f3f3f3;
  border-radius: 3.15px;
  padding: 6.3px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 37.78px;
  position: relative;
  overflow: hidden;
}
.frame_464 {
  background: #9bc9e3;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_474 {
  background: #4c9cb9;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_484 {
  background: #113045;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_494 {
  background: #f4b941;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_504 {
  background: #ed8a33;
  flex-shrink: 0;
  width: 12.59px;
  height: 25.19px;
  position: relative;
  overflow: hidden;
}
.frame_15 {
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_152 {
  background: #f3f3f3;
  border-radius: 3.15px;
  padding: 6.3px;
  display: flex;
  flex-direction: row;
  gap: 3.15px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.replay {
  flex-shrink: 0;
  width: 9.45px;
  height: 9.45px;
  position: relative;
  overflow: visible;
}
.refresh {
  color: #000000;
  text-align: left;
  font: 700 6.3px "ProductSans-Bold", sans-serif;
  position: relative;
}
.frame_465 {
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_93 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_68 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0.39px 0.39px 3.15px 0px rgba(0, 0, 0, 0.18);
}
.frame_10 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(-1, 1);
}
.rectangle_87 {
  background: var(--sufrace-surface-chat, #c6d8ff);
  border-radius: 0px 3.15px 0px 0px;
  flex-shrink: 0;
  width: 6.3px;
  height: 7.87px;
  position: relative;
}
.rectangle_117 {
  background: var(--sufrace-surface-chat, #c6d8ff);
  border-radius: 0px 0px 3.15px 0px;
  flex-shrink: 0;
  width: 6.3px;
  height: 7.87px;
  position: relative;
}
.frame_65 {
  background: #cfd2ff;
  padding: 4.72px 0px 4.72px 0px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  min-width: 59.04px;
  max-width: 196.79px;
  position: relative;
}
.frame_523 {
  background: #ffffff;
  border-radius: 3.15px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 25.19px;
  position: relative;
  box-shadow: 0px 0px 3.15px 0px rgba(0, 0, 0, 0.16),
    0px 1.57px 3.15px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.frame_94 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_485 {
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_84 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_66 {
  background: #cfd2ff;
  padding: 4.72px 0px 4.72px 0px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width: 196.79px;
  position: relative;
}
.frame_18 {
  padding: 0px 6.3px 6.3px 6.3px;
  display: flex;
  flex-direction: column;
  gap: 3.15px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_3 {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6.3px;
  border-style: solid;
  border-color: #d2d2d2;
  border-width: 0.39px;
  padding: 4.72px 4.72px 4.72px 7.87px;
  display: flex;
  flex-direction: row;
  gap: 6.3px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 0px 3.15px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.frame_4 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.line_1 {
  border-style: solid;
  border-color: #000000;
  border-width: 0.39px 0 0 0;
  flex-shrink: 0;
  width: 9.45px;
  height: 0px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(90deg) scale(1, 1);
}
.describe_your_idea_in_once_sentence {
  color: #7f7f7f;
  text-align: left;
  font: 400 6.3px "ProductSans-Regular", sans-serif;
  position: relative;
}
.send {
  flex-shrink: 0;
  width: 12.59px;
  height: 12.59px;
  position: relative;
  overflow: visible;
}
._1697770914142_nudge_20_desktop_3026_b_438275_d_4_bf_98338506_b_05_a_36_e_2_apng_1 {
  width: 356.58px;
  height: 273.53px;
  position: absolute;
  left: 8.13px;
  top: 62.18px;
}
.created2 {
  background: #f8f8f8;
  border-radius: 12.84px;
  width: 371px;
  height: 336px;
  position: absolute;
  left: -23px;
  top: 194px;
  box-shadow: 0px 4.72px 23px -1.57px rgba(69, 69, 69, 0.05),
    0px 2.36px 2.36px -0.79px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.eye2 {
  flex-shrink: 0;
  width: 6.3px;
  height: 6.3px;
  position: relative;
  overflow: visible;
}
.download2 {
  flex-shrink: 0;
  width: 6.3px;
  height: 6.3px;
  position: relative;
  overflow: visible;
}
.group2 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.frame_85 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_95 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_96 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.replay3 {
  flex-shrink: 0;
  width: 9.45px;
  height: 9.45px;
  position: relative;
  overflow: visible;
}
.frame_97 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_98 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_86 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.send3 {
  flex-shrink: 0;
  width: 12.59px;
  height: 12.59px;
  position: relative;
  overflow: visible;
}
._1697770914158_nudge_20_mobilebfea_2_cd_47_dc_343808_e_31555_d_9_ed_1_ce_5_dpng_1 {
  width: 124px;
  height: 271px;
  position: absolute;
  left: 116px;
  top: 65px;
}
.button_group2 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 300px);
  top: 53px;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
}
.button_group_base4 {
  background: #d6d3ff;
  border-radius: 9.37px 0px 0px 9.37px;
  border-style: solid;
  border-color: #d0d5dd;
  border-width: 1.17px;
  padding: 11.71px 18.74px 11.71px 18.74px;
  display: flex;
  flex-direction: row;
  gap: 9.37px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.laptop_mac {
  flex-shrink: 0;
  width: 23.43px;
  height: 23.43px;
  position: relative;
  overflow: visible;
}
.text3 {
  color: #344054;
  text-align: left;
  font: 500 16.4px/23.43px "Inter-Medium", sans-serif;
  position: relative;
}
.top_line2 {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  height: 1.17px;
  position: relative;
}
.content6 {
  padding: 10.54px 18.74px 10.54px 18.74px;
  display: flex;
  flex-direction: row;
  gap: 9.37px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.smartphone {
  flex-shrink: 0;
  width: 23.43px;
  height: 23.43px;
  position: relative;
  overflow: visible;
}
.bottom_line2 {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  height: 1.17px;
  position: relative;
}
.divider2 {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  width: 1.17px;
  position: relative;
}
.button_group_base5 {
  background: #ffffff;
  border-radius: 0px 9.37px 9.37px 0px;
  border-style: solid;
  border-color: #d0d5dd;
  border-width: 1.17px;
  padding: 11.71px 14.06px 11.71px 14.06px;
  display: flex;
  flex-direction: row;
  gap: 9.37px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.tablet_mac {
  flex-shrink: 0;
  width: 23.43px;
  height: 23.43px;
  position: relative;
  overflow: visible;
}
.content7 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 524px;
  position: relative;
}
.smartphone3 {
  height: auto;
  position: absolute;
  left: 12px;
  top: 12.5px;
  overflow: visible;
}
.benefit3 {
  display: flex;
  flex-direction: row;
  gap: 144px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 1328px;
  height: 573px;
  position: relative;
}
.bar_chart_2 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 12px;
  top: 12.5px;
  overflow: visible;
}
.baner {
  padding: 40px 56px 40px 56px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 1440px;
  position: relative;
}
.txt {
  background: linear-gradient(
    -88.47deg,
    rgba(151, 80, 206, 1) 0%,
    rgba(87, 62, 169, 1) 100%
  );
  border-radius: 12px;
  padding: 32px 44px 32px 44px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 1328px;
  height: 314px;
  position: relative;
}
._2_6 {
  flex-shrink: 0;
  width: 176.61px;
  height: 176.61px;
  position: absolute;
  left: 884.69px;
  top: -14px;
}
._2_7 {
  flex-shrink: 0;
  width: 73.82px;
  height: 73.82px;
  position: absolute;
  left: 736.31px;
  top: 136.14px;
}
._2_10 {
  flex-shrink: 0;
  width: 110.85px;
  height: 110.85px;
  position: absolute;
  left: 773.84px;
  top: 158.85px;
}
._2_8 {
  flex-shrink: 0;
  width: 60.06px;
  height: 60.06px;
  position: absolute;
  left: 1205.41px;
  top: 5.52px;
}
._2_9 {
  flex-shrink: 0;
  width: 109.62px;
  height: 109.62px;
  position: absolute;
  left: 1235px;
  top: 204px;
}
.txt2 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.txt3 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.start_your_30_day_free_trial {
  color: #ffffff;
  text-align: left;
  font: 600 44px "Inter-SemiBold", sans-serif;
  position: relative;
}
.get_up_and_running_in_less_than_5_minutes {
  color: #e9d7fe;
  text-align: left;
  font: 400 20px/30px "Inter-Regular", sans-serif;
  position: relative;
  width: 509px;
}
.button {
  background: #ffffff;
  border-radius: 100px;
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 64px;
  position: relative;
  overflow: hidden;
}
.get_started {
  color: #6f45b7;
  text-align: center;
  font: 600 18px/124% "Inter-SemiBold", sans-serif;
  position: relative;
}
.frame_128 {
  background: #ede7ff;
  border-radius: 12px 12px 0px 0px;
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 1px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 308px;
  height: 271px;
  position: absolute;
  left: 957px;
  top: 43px;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.frame_125 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_163 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_124 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.pro {
  color: #4541e5;
  text-align: left;
  font: 600 24px "Poppins-SemiBold", sans-serif;
  position: relative;
}
.frame_122 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
._10 {
  color: #000000;
  text-align: left;
  font: 500 48px "Poppins-Medium", sans-serif;
  position: relative;
}
._ {
  color: #686868;
  text-align: left;
  font: 400 18px "Poppins-Regular", sans-serif;
  position: relative;
}
.month {
  color: #686868;
  text-align: left;
  font: 400 18px "Poppins-Regular", sans-serif;
  position: relative;
}
.frame_121 {
  background: #fceaf6;
  border-radius: 4px;
  border-style: solid;
  border-color: #ff67a5;
  border-width: 1px;
  padding: 4px 8px 4px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.popular {
  color: #ff67a5;
  text-align: left;
  font: 400 14px "Helvetica-Regular", sans-serif;
  position: relative;
}
.frame_126 {
  background: #6c4ade;
  border-radius: 8px;
  padding: 12px 8px 12px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.enroll {
  color: #ffffff;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.frame_102 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_103 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.component_1_check {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.one_website {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "Poppins-Regular", sans-serif;
  position: relative;
}
.frame_142 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.component_1_check2 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.interest_list {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "Poppins-Regular", sans-serif;
  position: relative;
}
.frame_112 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.component_1_check3 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.seo_management {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "Poppins-Regular", sans-serif;
  position: relative;
}
.frame_174 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.close_cr_fr {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.settings {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "Poppins-Regular", sans-serif;
  position: relative;
  width: 225px;
}
.frame_192 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.close_cr_fr2 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.billing {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "Poppins-Regular", sans-serif;
  position: relative;
}
.close_cr_fr3 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.integrations {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "Poppins-Regular", sans-serif;
  position: relative;
}
.frame_130 {
  padding: 24px 56px 0px 56px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 1440px;
  position: relative;
}
.frame_1302 {
  background: #ffffff;
  border-radius: 12px;
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 1px;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.basic {
  color: #4541e5;
  text-align: left;
  font: 700 24px/24px "ProductSans-Bold", sans-serif;
  position: relative;
}
.free {
  color: #000000;
  text-align: left;
  font: 400 48px "ProductSans-Regular", sans-serif;
  position: relative;
}
.forever {
  color: #686868;
  text-align: left;
  font: 400 18px "ProductSans-Regular", sans-serif;
  position: relative;
}
.enroll2 {
  color: #ffffff;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check4 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.one_website2 {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check5 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.interest_list2 {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check6 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.seo_management2 {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check7 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.settings2 {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
  width: 225px;
}
.component_1_check8 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.billing2 {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check9 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.integrations2 {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1282 {
  background: #ffffff;
  border-radius: 12px;
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 1px;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.frame_1632 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.pro2 {
  color: #4541e5;
  text-align: left;
  font: 700 24px/24px "ProductSans-Bold", sans-serif;
  position: relative;
}
._102 {
  color: #000000;
  text-align: left;
  font: 400 48px "ProductSans-Regular", sans-serif;
  position: relative;
}
.month2 {
  color: #686868;
  text-align: left;
  font: 400 18px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check10 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.up_to_3_website {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check11 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.component_1_check12 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.close_cr_fr4 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.close_cr_fr5 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.close_cr_fr6 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.frame_129 {
  background: #ffffff;
  border-radius: 12px;
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 1px;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.ultimate {
  color: #4541e5;
  text-align: left;
  font: 700 24px/24px "ProductSans-Bold", sans-serif;
  position: relative;
}
.component_1_check13 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.unlimited_websites {
  color: #0a0a0a;
  text-align: left;
  font: 400 14px/16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.component_1_check14 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.component_1_check15 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.component_1_check16 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.component_1_check17 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.component_1_check18 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.frame_273 {
  padding: 80px 56px 80px 56px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_270 {
  background: #f9fafb;
  border-radius: 12px;
  padding: 56px 44px 56px 44px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 1328px;
  position: relative;
}
.content8 {
  display: flex;
  flex-direction: column;
  gap: 38px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 716px;
  position: relative;
}
.content9 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 716px;
  position: relative;
}
.stars {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.star_icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}
.star_background {
  border-radius: 0px;
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: -2.5%;
  top: 2.5%;
  height: 100%;
  overflow: visible;
}
.star {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}
.star_background2 {
  border-radius: 0px;
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: -2.5%;
  top: 2.5%;
  height: 100%;
  overflow: visible;
}
.star3 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}
.star_background3 {
  border-radius: 0px;
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: -2.5%;
  top: 2.5%;
  height: 100%;
  overflow: visible;
}
.star5 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}
.star_background4 {
  border-radius: 0px;
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: -2.5%;
  top: 2.5%;
  height: 100%;
  overflow: visible;
}
.star7 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}
.star_background5 {
  border-radius: 0px;
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: -2.5%;
  top: 2.5%;
  height: 100%;
  overflow: visible;
}
.star9 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}
.heading3 {
  color: #101828;
  text-align: left;
  font: 400 32px/48px "ProductSans-Regular", sans-serif;
  position: relative;
  width: 716px;
}
.txt4 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 608px;
  position: relative;
}
.text4 {
  color: #000000;
  text-align: left;
  font: 400 20px/28px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.supporting_text3 {
  color: #000000;
  text-align: left;
  font: 400 16px/24px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_272 {
  background: #6f45b7;
  border-radius: 0px 12px 12px 0px;
  padding: 21px 12px 21px 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 556px;
  height: 446px;
  position: absolute;
  left: 772px;
  top: 0px;
}
.rectangle_532 {
  border-radius: 14px;
  flex-shrink: 0;
  width: 508px;
  height: 330px;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.arrow {
  display: flex;
  flex-direction: row;
  gap: 28px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.chevron_down {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  position: relative;
  transform: translate(-44px, 0px);
  overflow: visible;
}
.chevron_down2 {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  position: relative;
  transform: translate(0px, -44px);
  overflow: visible;
}
.content10 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: absolute;
  left: calc(300px);
  top: 148px;
}
.text5 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.transform_your_vision_into_a_site {
  color: #000000;
  text-align: center;
  font: 700 72px/120% "ProductSans-Bold", sans-serif;
  position: relative;
  width: 766px;
}
.convenient_generation_of_your_ideas {
  text-align: center;
  font: 400 18px/148% "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.convenient_generation_of_your_ideas_span {
  color: #636363;
  font: 400 18px/148% "ProductSans-Regular", sans-serif;
}
.convenient_generation_of_your_ideas_span2 {
  color: #6141af;
  font: 400 18px/148% "ProductSans-Regular", sans-serif;
}
.convenient_generation_of_your_ideas_span3 {
  color: #000000;
  font: 400 18px/148% "ProductSans-Regular", sans-serif;
}
.action2 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.button2 {
  background: rgba(153, 80, 206, 0.17);
  border-radius: 100px;
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 64px;
  position: relative;
  overflow: hidden;
}
.get_pro {
  color: #6f45b7;
  text-align: center;
  font: 700 18px/124% "ProductSans-Bold", sans-serif;
  position: relative;
}
.button3 {
  background: linear-gradient(
    90deg,
    rgba(153, 80, 206, 1) 0%,
    rgba(87, 62, 169, 1) 100%
  );
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.ellipse_10 {
  background: linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.get_started_free {
  color: var(--text-on-invert-primary, #ffffff);
  text-align: center;
  font: 700 16px/124% "ProductSans-Bold", sans-serif;
  position: relative;
}
.get_started_free:hover{
  cursor: pointer;
}

.chevron_right {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 1328px;
  position: absolute;
  left: 64px;
  top: 24px;
}
.frame_274 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_22 {
  background: radial-gradient(
      closest-side,
      rgba(153, 80, 206, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.ai_builder2 {
  color: #000000;
  text-align: left;
  font: 700 20px "Inter-Bold", sans-serif;
  position: relative;
}
.chevron_right2 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.container2 {
  padding: 0px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 1280px;
  position: absolute;
  left: 80px;
  top: 486px;
}
.content11 {
  align-self: stretch;
  flex-shrink: 0;
  height: 575px;
  position: relative;
}
.macbook_pro_16_mockup {
  width: 1216px;
  height: 575px;
  position: absolute;
  left: calc(50% - 608px);
  top: 0px;
  overflow: hidden;
}
.shadow {
  position: absolute;
  right: -0.02%;
  left: -1.67%;
  width: 101.69%;
  bottom: -23.23%;
  top: 121.89%;
  height: 1.34%;
}
.mac_book_pro_16 {
  position: absolute;
  right: 0.92%;
  left: 0.94%;
  width: 98.13%;
  bottom: -22.78%;
  top: 0%;
  height: 122.78%;
}
.camera {
  position: absolute;
  right: 49.43%;
  left: 50.1%;
  width: 0.47%;
  bottom: 96.95%;
  top: 2.06%;
  height: 0.99%;
}
.logo {
  position: absolute;
  right: 46.91%;
  left: 47.58%;
  width: 5.51%;
  bottom: -14.85%;
  top: 113.29%;
  height: 1.57%;
}
.screen_mockup_replace_fill {
  border-radius: 12px;
  position: absolute;
  right: 9.13%;
  left: 9.95%;
  width: 80.92%;
  bottom: -10.26%;
  top: 3.48%;
  height: 106.78%;
}
.chat2 {
  background: linear-gradient(to left, #ede7ff, #ede7ff),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 12px;
  width: 984px;
  height: 599.54px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}
.frame_162 {
  background: #f9f9fc;
  border-radius: 8.43px;
  border-style: solid;
  border-color: #eaeaea;
  border-width: 0.7px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 421.71px;
  position: absolute;
  left: 184.85px;
  top: 89.97px;
  box-shadow: 0px 8.43px 11.25px -2.81px rgba(11, 93, 153, 0.02),
    0px 4.22px 4.22px -1.41px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}
.frame_275 {
  background: #ffffff;
  border-style: solid;
  border-color: #efefef;
  border-width: 0.7px;
  padding: 11.25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 2.81px 5.62px 0px rgba(0, 0, 0, 0.04);
}
.frame_282 {
  display: flex;
  flex-direction: row;
  gap: 5.62px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ai_chat {
  color: #000000;
  text-align: left;
  font: 700 11.25px "Helvetica-Bold", sans-serif;
  position: relative;
}
.alert_circle {
  flex-shrink: 0;
  width: 11.24px;
  height: 11.24px;
  position: relative;
  overflow: visible;
}
.frame_193 {
  background: #ffffff;
  padding: 0px 11.25px 0px 11.25px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.frame_175 {
  padding: 0px 0px 0px 9.14px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 177.64px;
  position: relative;
}
.frame_87 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_67 {
  background: #accdff;
  padding: 8.43px 0px 8.43px 0px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_54 {
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.hi_how_can_i_help_you_today {
  color: #000000;
  text-align: left;
  font: 400 11.25px "Helvetica-Regular", sans-serif;
  position: relative;
}
.rectangle_810 {
  background: #accdff;
  border-radius: 0px 16.87px 0px 0px;
  flex-shrink: 0;
  width: 11.25px;
  height: 14.06px;
  position: relative;
}
.rectangle_1110 {
  background: #accdff;
  border-radius: 0px 0px 16.87px 0px;
  flex-shrink: 0;
  width: 11.25px;
  height: 14.06px;
  position: relative;
}
.frame_176 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_23 {
  background: radial-gradient(
      closest-side,
      rgba(113, 109, 250, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to left, #f3f2fe, #f3f2fe);
  border-radius: 50%;
  flex-shrink: 0;
  width: 28.11px;
  height: 28.11px;
  position: relative;
}
.frame_88 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_69 {
  background: #f3f2fe;
  padding: 8.43px 0px 8.43px 0px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.i_will_help_you_to_build_the_best_website {
  color: #000000;
  text-align: left;
  font: 400 11.25px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.rectangle_812 {
  background: #f3f2fe;
  border-radius: 0px 16.87px 0px 0px;
  flex-shrink: 0;
  width: 11.25px;
  height: 14.06px;
  position: relative;
}
.rectangle_1112 {
  background: #f3f2fe;
  border-radius: 0px 0px 16.87px 0px;
  flex-shrink: 0;
  width: 11.25px;
  height: 14.06px;
  position: relative;
}
.frame_177 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.rectangle_813 {
  background: #6c4ade;
  border-radius: 0px 16.87px 0px 0px;
  flex-shrink: 0;
  width: 11.25px;
  height: 14.06px;
  position: relative;
}
.rectangle_108 {
  background: #6c4ade;
  flex: 1;
  width: 11.25px;
  position: relative;
}
.rectangle_1113 {
  background: #6c4ade;
  border-radius: 0px 0px 16.87px 0px;
  flex-shrink: 0;
  width: 11.25px;
  height: 14.06px;
  position: relative;
}
.frame_610 {
  background: #6c4ade;
  padding: 8.43px 0px 8.43px 0px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.website_for_karate_school {
  color: #ffffff;
  text-align: left;
  font: 400 11.25px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_89 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  transform: translate(-20.38px, 0px);
  overflow: visible;
}
.ellipse_24 {
  background: linear-gradient(to left, #e3e2fb, #e3e2fb),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 28.11px;
  height: 28.11px;
  position: relative;
}
.frame_182 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_810 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.frame_611 {
  background: #f3f2fe;
  padding: 8.43px 0px 8.43px 0px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 351.43px;
  position: relative;
}
.frame_55 {
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.that_sounds_great_i_found_a_couple_colour_palettes_that_will_look_great_with_karate_school_please_choose_one_from_the_following_options {
  color: #000000;
  text-align: left;
  font: 400 11.25px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_194 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_143 {
  background: #6c4ade;
  border-radius: 5.62px 0px 0px 5.62px;
  padding: 11.25px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.screenshot_2023_11_20_at_1_00_2 {
  flex-shrink: 0;
  width: 113.22px;
  height: 44.98px;
  position: relative;
}
.frame_811 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  transform: translate(-20.38px, 0px);
  overflow: visible;
}
.frame_20 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_812 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}
.what_is_your_goal_for_creating_the_website {
  color: #000000;
  text-align: left;
  font: 400 11.25px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_21 {
  padding: 0px 0px 0px 7.73px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.contnet {
  display: flex;
  flex-direction: row;
  gap: 7.03px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.answer {
  background: #ded4ff;
  border-radius: 5.62px;
  padding: 11.25px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 33.74px;
  position: relative;
}
.provide_information_about_our_company_organization {
  color: #6c4ade;
  text-align: left;
  font: 400 9.84px "Helvetica-Regular", sans-serif;
  position: relative;
}
.answer2 {
  background: #ededff;
  border-radius: 5.62px;
  padding: 11.25px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 33.74px;
  position: relative;
}
.sell_products_or_services_online {
  color: #000000;
  text-align: left;
  font: 400 9.84px "Helvetica-Regular", sans-serif;
  position: relative;
}
.or_write_your_answer_below {
  color: #000000;
  text-align: left;
  font: 400 9.84px "Helvetica-Regular", sans-serif;
  position: relative;
}
.frame_222 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.provide_information_about_our_company_organization2 {
  color: #ffffff;
  text-align: left;
  font: 400 9.84px "Helvetica-Regular", sans-serif;
  position: relative;
}
.frame_813 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  transform: translate(-20.38px, 0px);
  overflow: visible;
}
.frame_183 {
  padding: 11.25px;
  display: flex;
  flex-direction: column;
  gap: 5.62px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_32 {
  background: #ffffff;
  border-radius: 5.62px;
  border-style: solid;
  border-color: #dedede;
  border-width: 0.7px;
  padding: 8.43px 8.43px 8.43px 14.06px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 615px;
  position: relative;
  box-shadow: 0px 0px 8.43px 0px rgba(0, 12, 77, 0.04);
  overflow: hidden;
}
.frame_42 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 344.4px;
  position: relative;
}
.line_12 {
  border-style: solid;
  border-color: #000000;
  border-width: 0.7px 0 0 0;
  flex-shrink: 0;
  width: 16.87px;
  height: 0px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(90deg) scale(1, 1);
}
.describe_your_idea {
  color: #7f7f7f;
  text-align: left;
  font: 400 11.25px "Helvetica-Regular", sans-serif;
  position: relative;
}
.send5 {
  flex-shrink: 0;
  width: 16.87px;
  height: 16.87px;
  position: relative;
  overflow: visible;
}
.frame_25 {
  background: #ffffff;
  border-style: solid;
  border-color: #eaeaea;
  border-width: 0px 0px 0.7px 0px;
  padding: 11.25px 28.11px 11.25px 28.11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 984px;
  position: absolute;
  left: 0px;
  top: 0px;
  box-shadow: 0px 4.22px 8.43px 0px rgba(3, 2, 44, 0.04);
}
.frame_276 {
  display: flex;
  flex-direction: row;
  gap: 5.62px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_25 {
  background: radial-gradient(
      closest-side,
      rgba(113, 109, 250, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 22.49px;
  height: 22.49px;
  position: relative;
}
.ai_builder3 {
  color: #000000;
  text-align: left;
  font: 700 11.25px "Helvetica-Bold", sans-serif;
  position: relative;
}
.action3 {
  display: flex;
  flex-direction: row;
  gap: 5.62px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_232 {
  background: linear-gradient(
      to left,
      rgba(108, 74, 222, 0.12),
      rgba(108, 74, 222, 0.12)
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 5.62px;
  padding: 8.43px 11.25px 8.43px 11.25px;
  display: flex;
  flex-direction: row;
  gap: 5.62px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.log_in {
  color: #6c4ade;
  text-align: left;
  font: 500 11.25px "Inter-Medium", sans-serif;
  position: relative;
}
.frame_242 {
  background: #6c4ade;
  border-radius: 5.62px;
  padding: 8.43px 11.25px 8.43px 11.25px;
  display: flex;
  flex-direction: row;
  gap: 5.62px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.sign_up {
  color: #ffffff;
  text-align: left;
  font: 500 11.25px "Inter-Medium", sans-serif;
  position: relative;
}
.frame_178 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  right: 23.74%;
  left: -21.95%;
  width: 98.21%;
  bottom: 85.61%;
  top: 4.35%;
  height: 10.04%;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  transform-origin: 0 0;
  transform: rotate(7deg) scale(1, 1);
}
.message3 {
  flex-shrink: 0;
  width: 308.42px;
  height: 57.76px;
  position: static;
}
.frame_74 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 57.76px;
  position: absolute;
  left: 907.65px;
  top: 0px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(-1, 1);
}
.frame_612 {
  background: #6c4ade;
  padding: 16.38px 0px 16.38px 0px;
  display: flex;
  flex-direction: column;
  gap: 10.92px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 907.65px;
  top: 0px;
}
.website_for_karate_school2 {
  color: #ffffff;
  text-align: left;
  font: 400 21.84px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_814 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  height: 57.76px;
  position: absolute;
  left: 1194.23px;
  top: 0px;
  transform: translate(-46.32px, -4.82px);
  overflow: visible;
}
.frame_184 {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: flex-end;
  justify-content: flex-start;
  position: absolute;
  right: 54.94%;
  left: 5.02%;
  width: 40.05%;
  bottom: 48.18%;
  top: 41.62%;
  height: 10.19%;
  transform-origin: 0 0;
  transform: rotate(-7deg) scale(1, 1);
}
.ellipse_26 {
  background: radial-gradient(
      closest-side,
      rgba(113, 109, 250, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to left, #f3f2fe, #f3f2fe);
  border-radius: 50%;
  flex-shrink: 0;
  width: 53.7px;
  height: 53.7px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.message4 {
  flex: 1;
  height: 57.4px;
  position: static;
}
.frame_815 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  width: 22.3px;
  height: 57.4px;
  position: absolute;
  left: 67.7px;
  top: 1.2px;
  overflow: visible;
}
.frame_613 {
  background: #f3f2fe;
  padding: 16.11px 0px 16.11px 0px;
  display: flex;
  flex-direction: column;
  gap: 10.74px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 384.5px;
  position: absolute;
  left: 89.99px;
  top: 1.27px;
}
.frame_56 {
  display: flex;
  flex-direction: column;
  gap: 10.74px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.choose_the_colot_of_your_website {
  color: #000000;
  text-align: left;
  font: 400 21.48px "Helvetica-Regular", sans-serif;
  position: relative;
  align-self: stretch;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.frame_75 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 12.3px;
  height: 57.4px;
  position: absolute;
  left: 474.64px;
  top: 1.2px;
}
.rectangle_816 {
  background: #f3f2fe;
  border-radius: 0px 32.22px 0px 0px;
  flex-shrink: 0;
  width: 21.48px;
  height: 26.85px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.rectangle_1010 {
  background: #f3f2fe;
  flex-shrink: 0;
  width: 21.48px;
  height: 3.51px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.rectangle_1119 {
  background: #f3f2fe;
  border-radius: 0px 0px 32.22px 0px;
  flex-shrink: 0;
  width: 21.48px;
  height: 26.85px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.play_button {
  width: 80px;
  height: auto;
  position: absolute;
  left: calc(50% - 40px);
  bottom: 39.65%;
  top: 46.43%;
  height: 13.91%;
  overflow: visible;
}
.img2 {
  flex-shrink: 0;
  width: 656px;
  height: 573px;
  position: absolute;
  left: 728px;
  top: 2673px;
  overflow: hidden;
}
.content12 {
  width: 656px;
  height: 573px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}
.dashboard {
  background: #f8f8fc;
  border-radius: 0px 14px 0px 14px;
  width: 603px;
  height: 501px;
  position: absolute;
  left: 0px;
  top: 72px;
  box-shadow: 11px -8px 24px 0px rgba(32, 0, 162, 0.06);
  overflow: hidden;
}
.header3 {
  background: #ffffff;
  border-style: solid;
  border-color: #ebebeb;
  border-width: 0px 0px 1px 0px;
  padding: 0px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  height: 64px;
  position: absolute;
  left: -797px;
  top: 0px;
}
.frame_982 {
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 798px;
  position: relative;
}
.ellipse_27 {
  background: radial-gradient(
      closest-side,
      rgba(108, 74, 222, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.ai_builder4 {
  color: #000000;
  text-align: left;
  font: 700 16px "Helvetica-Bold", sans-serif;
  position: relative;
}
.frame_983 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.dashboard2 {
  border-style: solid;
  border-color: #4541e5;
  border-width: 0px 0px 1px 0px;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.dashboard3 {
  color: #4541e5;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.all_sites {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.learn {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.examples {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.examples2 {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.person {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_9 {
  border-radius: 50%;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  position: relative;
}
.chevron_down3 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.nav_menu {
  background: #ffffff;
  padding: 32px 14px 32px 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 342px;
  height: 777px;
  position: absolute;
  left: -797px;
  top: 64px;
}
.person2 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 212px;
  position: relative;
}
.jane_cooper {
  color: #000000;
  text-align: left;
  font: 400 16px "Helvetica-Regular", sans-serif;
  position: relative;
}
.menu {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.menu2 {
  border-radius: 8px;
  padding: 12px 14px 12px 14px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
._3_layers {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.all_sites2 {
  color: #000000;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.nav {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.settings3 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.menu3 {
  background: #f4f5ff;
  border-radius: 8px;
  padding: 12px 14px 12px 14px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.bar_chart_22 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.settings4 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.settings5 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_252 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 995px;
  position: absolute;
  left: -432px;
  top: 96px;
}
.button_group3 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.button_group_base6 {
  background: #f9fafb;
  border-radius: 8px 0px 0px 8px;
  border-style: solid;
  border-color: #d0d5dd;
  border-width: 1px;
  padding: 10px 16px 10px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.text6 {
  color: #344054;
  text-align: left;
  font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
}
.top_line3 {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  height: 1px;
  position: relative;
}
.content13 {
  padding: 9px 16px 9px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.bottom_line3 {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  height: 1px;
  position: relative;
}
.divider3 {
  background: #d0d5dd;
  align-self: stretch;
  flex-shrink: 0;
  width: 1px;
  position: relative;
}
.button_group_base7 {
  background: #ffffff;
  border-radius: 0px 8px 8px 0px;
  border-style: solid;
  border-color: #d0d5dd;
  border-width: 1px;
  padding: 10px 12px 10px 12px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.action4 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.date {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 42px;
  position: relative;
}
.btn {
  background: #ffffff;
  border-radius: 8px;
  border-style: solid;
  border-color: #e9e9e9;
  border-width: 1px;
  padding: 10px 16px 10px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 42px;
  position: relative;
  box-shadow: var(
    --shadow-xs-box-shadow,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05)
  );
  overflow: hidden;
}
.calendar {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.text7 {
  color: #667085;
  text-align: left;
  font: 500 14px/20px "Inter-Medium", sans-serif;
  position: relative;
}
.frame_233 {
  background: linear-gradient(
      to left,
      rgba(108, 74, 222, 0.12),
      rgba(108, 74, 222, 0.12)
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.share {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.share2 {
  color: #6c4ade;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.frame_243 {
  background: #6c4ade;
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.download3 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.export_review {
  color: #ffffff;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.cintent {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-start;
  width: 994px;
  position: absolute;
  left: -431px;
  top: 164px;
}
.frame_1522 {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.frame_147 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.views {
  color: #000000;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.more_vertical {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.frame_151 {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_150 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
._8_8_k {
  color: #000000;
  text-align: left;
  font: 600 40px "Inter-SemiBold", sans-serif;
  position: relative;
}
.frame_148 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_149 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.arrow_upward {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
._12 {
  color: #51b467;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.vs_last_month {
  color: #888888;
  text-align: left;
  font: 400 14px "Inter-Regular", sans-serif;
  position: relative;
}
.group_14 {
  flex: 1;
  height: 80.14px;
  position: relative;
  overflow: visible;
}
.frame_256 {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.sessions {
  color: #000000;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.more_vertical2 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.arrow_upward3 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
._122 {
  color: #ee5e55;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.group_142 {
  flex-shrink: 0;
  width: 123.67px;
  height: 80.14px;
  position: relative;
  transform: translate(-123.74px, -0.5px);
  overflow: visible;
}
.frame_257 {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.session_duration {
  color: #000000;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.more_vertical3 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
._12_h {
  color: #000000;
  text-align: left;
  font: 600 40px "Inter-SemiBold", sans-serif;
  position: relative;
}
.arrow_upward5 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.group_143 {
  flex: 1;
  height: 80.14px;
  position: relative;
  overflow: visible;
}
.frame_134 {
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  width: 994px;
  position: absolute;
  left: -431px;
  top: 351px;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
}
.frame_1432 {
  padding: 0px 24px 0px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_144 {
  border-style: solid;
  border-color: #ebebeb;
  border-width: 0px 0px 1px 0px;
  padding: 24px 0px 24px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
}
.world_visitors {
  color: #000000;
  text-align: left;
  font: 500 16px "Inter-Medium", sans-serif;
  position: relative;
}
.frame_244 {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: #ebebeb;
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.lab_profile {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.real_time_report {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1422 {
  padding: 24px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_135 {
  flex-shrink: 0;
  width: 594.27px;
  height: 334.5px;
  position: relative;
}
.g_10 {
  height: auto;
  position: absolute;
  left: 0px;
  top: 334.5px;
  transform: translate(369.14px, -334.5px);
  overflow: visible;
}
.group_12 {
  position: absolute;
  inset: 0;
}
.ellipse_242 {
  background: rgba(69, 65, 229, 0.25);
  border-radius: 50%;
  width: 4.2px;
  height: 4.2px;
  position: absolute;
  left: 263.04px;
  top: 113.4px;
}
.ellipse_252 {
  background: rgba(69, 65, 229, 0.25);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 262.13px;
  top: 112.5px;
}
.group_5 {
  position: absolute;
  inset: 0;
}
.ellipse_243 {
  background: rgba(69, 65, 229, 0.25);
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 156.63px;
  top: 129px;
}
.ellipse_253 {
  background: rgba(69, 65, 229, 0.25);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 155.13px;
  top: 127.5px;
}
.group_4 {
  position: absolute;
  inset: 0;
}
.ellipse_244 {
  background: rgba(69, 65, 229, 0.25);
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 273.63px;
  top: 114px;
}
.ellipse_254 {
  background: rgba(69, 65, 229, 0.25);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 272.13px;
  top: 112.5px;
}
.image_1 {
  width: 19px;
  height: 26px;
  position: absolute;
  left: 271.13px;
  top: 116.5px;
}
.map {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 256.13px;
  top: 98.5px;
}
.circle {
  background: #6c4ade;
  border-radius: 28px;
  opacity: 0.10000000149011612;
  width: 40px;
  height: 40px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}
.circle2 {
  background: #6c4ade;
  border-radius: 28px;
  opacity: 0.20000000298023224;
  width: 24px;
  height: 24px;
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
}
.circle3 {
  background: #6c4ade;
  border-radius: 28px;
  width: 8px;
  height: 8px;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 4px);
}
.map2 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 483.13px;
  top: 40.5px;
}
.map3 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 483.13px;
  top: 222.5px;
}
.map4 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 139.13px;
  top: 112.5px;
}
.map5 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 162.13px;
  top: 257.5px;
}
.map6 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 29.13px;
  top: 17.5px;
}
.group_13 {
  position: absolute;
  inset: 0;
}
.union36 {
  width: 152px;
  height: 79.75px;
  position: absolute;
  left: 203.13px;
  top: 34.5px;
  overflow: visible;
}
.frame_146 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 219.13px;
  top: 50.5px;
}
.ellipse_232 {
  border-radius: 50%;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.frame_145 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.london_uk {
  color: #000000;
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
._523_users {
  color: #424242;
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_136 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_141 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_137 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_138 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.united_states {
  color: #000000;
  text-align: left;
  font: 400 16px "Inter-Regular", sans-serif;
  position: relative;
}
.frame_140 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_139 {
  flex-shrink: 0;
  width: 164px;
  height: 6px;
  position: relative;
}
.rectangle_28 {
  background: #ebebeb;
  border-radius: 8px;
  width: 164px;
  height: 6px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.rectangle_27 {
  background: #6c4ade;
  border-radius: 8px;
  width: 100px;
  height: 6px;
  position: absolute;
  left: 0px;
  top: 0px;
}
._55 {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1382 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.canada {
  color: #000000;
  text-align: left;
  font: 400 16px "Inter-Regular", sans-serif;
  position: relative;
}
.rectangle_272 {
  background: #6c4ade;
  border-radius: 8px;
  width: 76px;
  height: 6px;
  position: absolute;
  left: -0.27px;
  top: 0.5px;
}
._45 {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1392 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.australia {
  color: #000000;
  text-align: left;
  font: 400 16px "Inter-Regular", sans-serif;
  position: relative;
}
.frame_1393 {
  flex-shrink: 0;
  width: 164px;
  height: 8px;
  position: relative;
}
.rectangle_282 {
  background: #ebebeb;
  border-radius: 8px;
  width: 164px;
  height: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.rectangle_273 {
  background: #6c4ade;
  border-radius: 8px;
  width: 52px;
  height: 8px;
  position: absolute;
  left: -0.27px;
  top: 0.5px;
}
._25 {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1402 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.united_kingdom {
  color: #000000;
  text-align: left;
  font: 400 16px "Inter-Regular", sans-serif;
  position: relative;
}
.rectangle_274 {
  background: #6c4ade;
  border-radius: 8px;
  width: 34px;
  height: 6px;
  position: absolute;
  left: -0.27px;
  top: 0.5px;
}
._15 {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1412 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ireland {
  color: #000000;
  text-align: left;
  font: 400 16px "Inter-Regular", sans-serif;
  position: relative;
}
.rectangle_275 {
  background: #6c4ade;
  border-radius: 8px;
  width: 20px;
  height: 6px;
  position: absolute;
  left: -0.27px;
  top: 0.5px;
}
._103 {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.cintent2 {
  display: flex;
  flex-direction: row;
  gap: 12.78px;
  align-items: flex-end;
  justify-content: flex-start;
  width: 1059px;
  position: absolute;
  left: -431px;
  top: 235px;
}
.frame_1523 {
  background: #ffffff;
  border-radius: 17.05px;
  border-style: solid;
  border-color: transparent;
  border-width: 1.07px;
  padding: 25.57px;
  display: flex;
  flex-direction: column;
  gap: 17.05px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: 0px 12.78px 17.05px -4.26px rgba(11, 93, 153, 0.02),
    0px 6.39px 6.39px -2.13px rgba(0, 0, 0, 0.02);
  overflow: hidden;
}
.views2 {
  color: #000000;
  text-align: left;
  font: 500 17.05px "Inter-Medium", sans-serif;
  position: relative;
}
.more_vertical4 {
  flex-shrink: 0;
  width: 25.57px;
  height: 25.57px;
  position: relative;
  overflow: visible;
}
.frame_1512 {
  display: flex;
  flex-direction: row;
  gap: 9.59px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
._8_8_k2 {
  color: #000000;
  text-align: left;
  font: 600 42.62px "Inter-SemiBold", sans-serif;
  position: relative;
}
.frame_1482 {
  display: flex;
  flex-direction: row;
  gap: 4.26px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_1492 {
  display: flex;
  flex-direction: row;
  gap: 2.13px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.arrow_upward7 {
  flex-shrink: 0;
  width: 21.31px;
  height: 21.31px;
  position: relative;
  overflow: visible;
}
._123 {
  color: #51b467;
  text-align: left;
  font: 400 17.05px "ProductSans-Regular", sans-serif;
  position: relative;
}
.vs_last_month2 {
  color: #888888;
  text-align: left;
  font: 400 14.92px "Inter-Regular", sans-serif;
  position: relative;
}
.group_144 {
  flex: 1;
  height: 85.38px;
  position: relative;
  overflow: visible;
}
.frame_2562 {
  background: #ffffff;
  border-radius: 17.05px;
  border-style: solid;
  border-color: transparent;
  border-width: 1.07px;
  padding: 25.57px;
  display: flex;
  flex-direction: column;
  gap: 17.05px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.sessions2 {
  color: #000000;
  text-align: left;
  font: 500 17.05px "Inter-Medium", sans-serif;
  position: relative;
}
.more_vertical5 {
  flex-shrink: 0;
  width: 25.57px;
  height: 25.57px;
  position: relative;
  overflow: visible;
}
.arrow_upward9 {
  flex-shrink: 0;
  width: 21.31px;
  height: 21.31px;
  position: relative;
  overflow: visible;
}
._124 {
  color: #ee5e55;
  text-align: left;
  font: 400 17.05px "ProductSans-Regular", sans-serif;
  position: relative;
}
.group_145 {
  flex-shrink: 0;
  width: 131.75px;
  height: 85.38px;
  position: relative;
  transform: translate(-131.83px, -0.53px);
  overflow: visible;
}
.frame_2572 {
  background: #ffffff;
  border-radius: 17.05px;
  border-style: solid;
  border-color: transparent;
  border-width: 1.07px;
  padding: 25.57px;
  display: flex;
  flex-direction: column;
  gap: 17.05px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.session_duration2 {
  color: #000000;
  text-align: left;
  font: 500 17.05px "Inter-Medium", sans-serif;
  position: relative;
}
.more_vertical6 {
  flex-shrink: 0;
  width: 25.57px;
  height: 25.57px;
  position: relative;
  overflow: visible;
}
._12_h2 {
  color: #000000;
  text-align: left;
  font: 600 42.62px "Inter-SemiBold", sans-serif;
  position: relative;
}
.arrow_upward11 {
  flex-shrink: 0;
  width: 21.31px;
  height: 21.31px;
  position: relative;
  overflow: visible;
}
.group_146 {
  flex: 1;
  height: 85.38px;
  position: relative;
  overflow: visible;
}
