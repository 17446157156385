.desktop_47,
.desktop_47 * {
  box-sizing: border-box;
}
.main_window {
  background: linear-gradient(
      108.63deg,
      rgba(101, 101, 209, 0.6) 0%,
      rgba(24, 61, 186, 0.3) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 12px;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.polygon_1 {
  width: 26.82px;
  height: 26.82px;
  position: absolute;
  left: 62.99px;
  top: 370px;
  transform: translate(-8.27px, _0.01px);
  overflow: visible;
}
.polygon_3 {
  width: 41.66px;
  height: 37.72px;
  position: absolute;
  left: 226px;
  top: 727.51px;
  transform: translate(7.81px, _10.75px);
  overflow: visible;
}
.polygon_2 {
  width: 26.82px;
  height: 26.82px;
  position: absolute;
  left: 1235.17px;
  top: 251.79px;
  transform: translate(1.61px, _13.31px);
  overflow: visible;
}
.polygon_4 {
  width: 26.82px;
  height: 26.82px;
  position: absolute;
  left: 1187.19px;
  top: 561px;
  transform: translate(-10.13px, _5.41px);
  overflow: visible;
}
.star_1 {
  border-radius: 0px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 210.9px;
  top: 265.33px;
  transform: translate(0.44px, _6.99px);
  overflow: visible;
}
.star_2 {
  border-radius: 0px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 1183.18px;
  top: 125.68px;
  transform: translate(-5.95px, _0.45px);
  overflow: visible;
}
.line_2 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 86px;
  top: 545.52px;
  transform: translate(-4.59px, _24.43px);
  overflow: visible;
}
.line_3 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 1126.92px;
  top: 381.39px;
  transform: translate(-4.13px, _6px);
  overflow: visible;
}
.line_4 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 531.85px;
  top: 752.22px;
  transform: translate(-4.31px, _5.98px);
  overflow: visible;
}
.ellipse_19 {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 127px;
  top: 715px;
  filter: blur(2px);
}
.ellipse_20 {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 1311px;
  top: 390px;
  filter: blur(2px);
}
.line_5 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 233px;
  top: 61.52px;
  transform: translate(-4.59px, _24.43px);
  overflow: visible;
}
.star_3 {
  border-radius: 0px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 518.04px;
  top: 23.98px;
  transform: translate(-1.67px, _4.61px);
  overflow: visible;
}
.ellipse_21 {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 814px;
  top: 71px;
  filter: blur(2px);
}
.frame_79 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 1150.38px;
  top: 642px;
  transform-origin: 0 0;
  transform: rotate(45deg) scale(1, 1);
  filter: blur(4.5px);
}
.frame_74 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_12 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_18 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_17 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_16 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_15 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_14 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_13 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.frame_75 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_76 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_77 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_78 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_792 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_80 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_81 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_782 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 130.19px;
  top: 150.06px;
  transform-origin: 0 0;
  transform: rotate(54.699deg) scale(1, 1);
  filter: blur(2px);
}
.ellipse_122 {
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_182 {
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_172 {
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_162 {
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_152 {
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_142 {
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_132 {
  background: #ffffff;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.header {
  background: #ffffff;
  border-style: solid;
  border-color: #ebebeb;
  border-width: 0px 0px 1px 0px;
  padding: 0px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 80px);
  height: 64px;
  position: absolute;
  left: 0px;
  top: 0px;
  backdrop-filter: blur(8px);
}
.frame_20 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 132px;
  position: relative;
}
.ellipse_10 {
  background: linear-gradient(to left, #000000, #000000);
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.ai_builder {
  color: #000000;
  text-align: left;
  font: 600 16px "Montserrat-SemiBold", sans-serif;
  position: relative;
}
.frame_86 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.frame_84 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_83 {
  background: #6c4ade;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
}
._1 {
  color: #ffffff;
  text-align: left;
  font: 700 14px "Helvetica-Bold", sans-serif;
  position: relative;
}
.short_brief {
  color: #6c4ade;
  text-align: left;
  font: 400 14px "Helvetica-Regular", sans-serif;
  position: relative;
}
.remix_icons_line_system_arrow_drop_right_line {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}
.group {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.frame_85 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_832 {
  background: #878787;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
}
._2 {
  color: #ffffff;
  text-align: left;
  font: 700 14px "Helvetica-Bold", sans-serif;
  position: relative;
}
.edit {
  color: #878787;
  text-align: left;
  font: 400 14px "Helvetica-Regular", sans-serif;
  position: relative;
}
.group2 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.frame_862 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
._3 {
  color: #ffffff;
  text-align: left;
  font: 700 14px "Helvetica-Bold", sans-serif;
  position: relative;
}
.preview_and_publish {
  color: #878787;
  text-align: left;
  font: 400 14px "Helvetica-Regular", sans-serif;
  position: relative;
}
.frame_95 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.remix_icons_line_media_notification_3_line {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
}
.group3 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.frame_96 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_22 {
  background: linear-gradient(to left, #d9d9d9, #d9d9d9);
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.account {
  color: #000000;
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.remix_icons_line_system_arrow_down_s_line {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
}
.group4 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.chat_frame{
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100%);
  position: relative;
}
.chat {
  background: #ffffff;
  border-radius: 12px;
  border-style: solid;
  border-color: #bfbfbf;
  border-width: 1px;
  flex-shrink: 0;
  width: 70%;
  height: 70%;
  position: relative;
  box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.08),
    0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.frame_43 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.message_bubble {
/*  border-radius: 24px 2px 24px 24px;
  display: flex;
  flex-direction: row;
  gap: 0px;*/
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame_59 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.18);
  width:100%;
}

.width_100pct{
  width:100%;

}


.frame_8 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: auto;
  position: relative;
  overflow: visible;
}

.frame_5 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  max-width: 500px;
  position: relative;
}
.write_your_message_here_3 {
  color: #000000;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.frame_7 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.rectangle_82 {
  background: #f5f5f5;
  border-radius: 0px 8px 0px 0px;
  flex-shrink: 0;
  width: 16px;
  height: 20px;
  position: relative;
}
.rectangle_112 {
  background: #f5f5f5;
  border-radius: 0px 0px 8px 0px;
  flex-shrink: 0;
  width: 16px;
  height: 20px;
  position: relative;
}
.chat_header {
  background: rgba(255, 255, 255, 0.8);
  border-style: solid;
  border-color: #bfbfbf;
  border-width: 0px 0px 1px 0px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc( 100% );
  height:60px;
  position: relative;
  left: 0px;
  top: 0px;
  
}

.chat_messages_view1 {
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-end;
  width: calc( 100%  );
  height: 100px; /*;*/
  position: relative; 
  left: 0px;
  overflow-y: auto;
}



.chat_messages_view {
  align-items: flex-start;
  padding: 0px 16px 16px 16px;
  width: calc( 100% - 32px  );
  height:calc(75% );
  overflow-y: auto;
  gap: 2px;
  display: flex;
  position: relative; 
  left: 0px;
  flex-direction: column;
}


.chat_input_view {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc( 100% - 8px - 8px);
  position: relative;
  height: calc(25% ) ;
}

.frame_272 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_102 {
  background: linear-gradient(to left, #626262, #626262);
  border-radius: 50%;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.remix_icons_line_system_question_line {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}
.group5 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}

.frame_18 {
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 100%;
  top:0;
  position: relative;
}

.frame_3 {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  border-style: solid;
  border-color: #ebebeb;
  border-width: 1px;
  padding: 2px 2px 2px 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: 80%;
}

.chat_textfield_div {
  color: #7f7f7f;
  text-align: left;
  padding: 10px 10px 10px 10px;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
  width: 100%;
  height: 55px;
}

.chat_textfield {
  color: black;
  padding: 10px 30px 10px 10px;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  resize : none;
  outline: none;
}



.line_1 {
  border-style: solid;
  border-color: #000000;
  border-width: 1px 0 0 0;
  flex-shrink: 0;
  width: 24px;
  height: 0px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(90deg) scale(1, 1);
}

.send {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  overflow: visible;
}

.chat_bubble {
  padding: 10px 20px;
  border-radius: 20px;
  margin: 10px;
  font: 400 16px "ProductSans-Regular", sans-serif;
  display: inline-block;
  position: relative;
  word-wrap: break-word;
  white-space: normal;
  
}

/* Style for sender bubble */
.sender {
  background-color: #007bff;
  color: white;
  margin-left: auto;
}

/* Style for receiver bubble */
.receiver {
  background-color: #e9ecef;
  color: black;
}

.receiver::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: #e9ecef;
  border-bottom-right-radius: 15px;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.sender::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-bottom-left-radius: 15px;
/*  clip-path: polygon(0 0, 100% 0, 0 100%); */
  clip-path: polygon(0 0, 100% 100%, 0 100%);

  /*clip-path: polygon(100% 0, 0 100%, 100% 100%);*/

}


.ellipse_1 {
  background: #746feb;
  border-radius: 50%;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  position: relative;
}
.ellipse_2 {
  background: #a7a5f3;
  border-radius: 50%;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  position: relative;
}
.ellipse_3 {
  background: #a7a5f3;
  border-radius: 50%;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  position: relative;
}

.ellipse_frame {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 25px;
  position: relative;
}