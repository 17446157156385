
.button_panel {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.button_frame {
  background: #7654e8;
  border-radius: 8px;
  padding: 12px 40px 12px 40px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 43px;
  position: relative;
  cursor:pointer;
}
.button_text {
  color: #ffffff;
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}

.input_content {
  display: flex;
  flex-direction: column;
  gap: 1px;
  height: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin-top: 10px;
  position: relative;
  outline: none;
  font-size:medium;
}
.input_content:hover{
  outline: none;
 }

 .navlink {
  color: #2a79ff;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  text-decoration: underline;
  position: relative;
  cursor: pointer;
}
      a,
      button,
      input,
      select,
      h1,
      h2,
      h3,
      h4,
      h5,
      * {
        margin: 0;
        padding: 0;
        border: none;
        text-decoration: none;
        appearance: none;
        background: none;

        -webkit-font-smoothing: antialiased;
      }
    
.home,
.home * {
  box-sizing: border-box;
}
.home {
  background: #ffffff;
  width: 1440px;
  height: 6913px;
  position: relative;
  overflow: hidden;
  left:100px;
}
.bg {
  background: #f5f9fe;
  width: 1093px;
  height: 650px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.frame_3 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 100px;
  top: 528px;
}
.name {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.form {
  background: var(--base-white, #ffffff);
  border-radius: 6px;
  border-style: solid;
  border-color: var(--text-lighter, #e1e3e8);
  border-width: 1px;
  padding: 16px 16px 16px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 450px;
  position: relative;
}
.placeholder {
  color: #536288;
  text-align: left;
  font: 400 16px/160% "Outfit-Regular", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.button {
  background: var(--brand-primary, #1e5dbc);
  border-radius: 6px;
  padding: 16px 32px 16px 32px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.button_normal {
  color: var(--base-white, #ffffff);
  text-align: left;
  font: var(--website-button-normal, 700 16px/160% "Outfit-Bold", sans-serif);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame_2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 100px;
  top: 140px;
}
.your_capital_partner_in_med_tech_innovation {
  color: #1e5dbc;
  text-align: left;
  font: 800 56px/72px "Inter-ExtraBold", sans-serif;
  position: relative;
  width: 640px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame_1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.pradhan_capital_leverages_frontline_expertise_to_fuel_healthcare_technology {
  color: #4eb2f9;
  text-align: left;
  font: 500 24px/32px "Inter-Medium", sans-serif;
  position: relative;
  width: 640px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.led_by_renowned_orthopaedic_surgeon_anup_pradhan_our_investment_team_propels_innovators_and_investors_empowering_startups_that_reimagine_healthcare_through_technology_specializing_in_identifying_and_nurturing_promising_healthcare_tech_startups_we_drive_transformative_change {
  color: #536288;
  text-align: left;
  font: 400 16px/160% "Outfit-Regular", sans-serif;
  position: relative;
  width: 640px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.partner {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 100px;
  top: 692px;
}
.partnered_with {
  color: #536288;
  text-align: left;
  font: 400 16px/160% "Outfit-Regular", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame_5 {
  flex-shrink: 0;
  width: 181px;
  height: 42px;
  position: relative;
}
.group_39787 {
  position: absolute;
  inset: 0;
}
.image_2_removebg_preview_1 {
  width: 136px;
  height: 8.63px;
  position: absolute;
  left: 45px;
  top: calc(50% - -8.2px);
}
.image_2_removebg_preview_3 {
  width: 136px;
  height: 22.7px;
  position: absolute;
  left: 45px;
  top: calc(50% - 19.5px);
}
.image_2_removebg_preview_2 {
  width: 41.6px;
  height: 41px;
  position: absolute;
  left: 0px;
  top: calc(50% - 19.5px);
}
.image_1_removebg_preview_1 {
  flex-shrink: 0;
  width: 134.87px;
  height: 43px;
  position: relative;
}
.frame_39793 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.layer_1 {
  border-radius: 900px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: visible;
}
.dallas_dermatology_partners {
  color: #8b98b8;
  text-align: left;
  font: 700 12px/105% "DmSans-Bold", sans-serif;
  position: relative;
}
.logo {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 100px;
  top: 33px;
}
.mask_group {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: static;
}
.ellipse_443 {
  background: #c4c4c4;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0px;
  top: 4px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.group_39786 {
  width: 67.19px;
  height: 67.19px;
  position: static;
}
.ellipse_444 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 26.55px;
  height: 26.55px;
  position: absolute;
  left: -8.89px;
  top: -6.18px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_445 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 26.55px;
  height: 26.55px;
  position: absolute;
  left: 6.33px;
  top: 31.51px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_446 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 26.55px;
  height: 26.55px;
  position: absolute;
  left: -20.12px;
  top: 20.28px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_447 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 26.55px;
  height: 26.55px;
  position: absolute;
  left: 17.21px;
  top: 4.19px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.pradhan_capital {
  color: #536288;
  text-align: left;
  font: 700 20px/40px "Satoshi-Bold", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left:30px;
}
.button2 {
  background: var(--brand-primary, #1e5dbc);
  border-radius: 6px;
  padding: 16px 32px 16px 32px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 100px;
  top: 24px;
}
.image {
  width: 632px;
  height: 640px;
  position: absolute;
  right: 0px;
  top: 140px;
}
.frame_6 {
  background: #f5f9fe;
  width: 1440px;
  height: 840px;
  position: absolute;
  left: 0px;
  top: 1274px;
  overflow: hidden;
}
.frame_4 {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 335px);
  top: 120px;
}
.med_tech_startups {
  color: #4eb2f9;
  text-align: left;
  font: 500 16px/120% "Inter-Medium", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.why_partner_with_pradhan_capital {
  color: var(--text-darker, #0f2f64);
  text-align: center;
  font: var(--website-heading-h-2, 500 40px/120% "Inter-Medium", sans-serif);
  position: relative;
  width: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partnering_with_pradhan_capital_can_help_propel_your_early_stage_med_tech_startup_to_new_heights {
  color: var(--text-dark, #536288);
  text-align: center;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 566px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 420px;
  position: absolute;
  left: 100px;
  top: 307px;
}
.cards {
  background: var(--base-white, #ffffff);
  border-radius: 2px;
  padding: 48px;
  flex-shrink: 0;
  width: 396px;
  height: 420px;
  position: relative;
  overflow: hidden;
}
._1 {
  color: rgba(60, 80, 224, 0.03);
  text-align: left;
  font: 700 254px/160% "Overpass-Bold", sans-serif;
  position: absolute;
  right: 0px;
  top: -29px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content {
  padding: 48px 56px 48px 56px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 0px;
  top: 0px;
}
.icon {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  position: static;
}
.ellipse_4 {
  background: var(--accents-green, #1ebcb4);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 71.28%;
  left: 14.36%;
  width: 14.36%;
  bottom: 73.87%;
  top: 12.06%;
  height: 14.07%;
}
.icon_line_primary_care {
  position: absolute;
  right: 74.36%;
  left: 17.44%;
  width: 8.21%;
  bottom: 76.88%;
  top: 15.08%;
  height: 8.04%;
  overflow: hidden;
}
.group_22 {
  position: absolute;
  inset: 0;
}
.group {
  width: 27.33px;
  height: 27.33px;
  position: absolute;
  left: 2.33px;
  top: 2.33px;
  overflow: visible;
}
.group3 {
  width: 15.7px;
  height: 15.7px;
  position: static;
}
.group_23 {
  width: 13.33px;
  height: 13.33px;
  position: static;
}
.rectangle_20 {
  background: var(--base-white, #ffffff);
  position: absolute;
  right: 41.67%;
  left: 41.67%;
  width: 16.67%;
  bottom: 29.17%;
  top: 29.17%;
  height: 41.67%;
}
.rectangle_21 {
  background: var(--base-white, #ffffff);
  position: absolute;
  right: 54.17%;
  left: 29.17%;
  width: 16.67%;
  bottom: 0%;
  top: 58.33%;
  height: 41.67%;
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
}
.vector3 {
  height: auto;
  position: absolute;
  right: 26.51%;
  left: 26.51%;
  width: 46.99%;
  bottom: 26.51%;
  top: 26.51%;
  height: 46.99%;
  overflow: visible;
}
.vector_stroke2 {
  height: auto;
  position: absolute;
  right: 25.46%;
  left: 25.46%;
  width: 49.07%;
  bottom: 25.46%;
  top: 25.46%;
  height: 49.07%;
  overflow: visible;
}
.find_clinic {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(--website-heading-h-4, 500 24px/120% "Inter-Medium", sans-serif);
  position: relative;
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pradhan_capital_provides_more_than_just_capital_investment_we_offer_invaluable_professional_guidance_leveraging_our_extensive_network_and_expertise_to_accelerate_your_startup_s_development_read_more {
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pradhan_capital_provides_more_than_just_capital_investment_we_offer_invaluable_professional_guidance_leveraging_our_extensive_network_and_expertise_to_accelerate_your_startup_s_development_read_more_span {
  color: var(--text-dark, #536288);
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
}
.pradhan_capital_provides_more_than_just_capital_investment_we_offer_invaluable_professional_guidance_leveraging_our_extensive_network_and_expertise_to_accelerate_your_startup_s_development_read_more_span2 {
  color: #4eb2f9;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
}
._12 {
  color: rgba(60, 80, 224, 0.03);
  text-align: left;
  font: 700 254px/160% "Overpass-Bold", sans-serif;
  position: absolute;
  left: 290px;
  top: -29px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.icon2 {
  flex-shrink: 0;
  width: 55.97px;
  height: 56px;
  position: static;
}
.ellipse_42 {
  background: var(--accents-yellow, #ffb201);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 71.29%;
  left: 14.36%;
  width: 14.35%;
  bottom: 73.87%;
  top: 12.06%;
  height: 14.07%;
}
.icon_line_search {
  height: auto;
  position: absolute;
  right: 74.36%;
  left: 17.43%;
  width: 8.2%;
  bottom: 76.88%;
  top: 15.08%;
  height: 8.04%;
  overflow: visible;
}
.our_comprehensive_knowledge_and_extensive_experience_in_healthcare_as_providers_and_users_of_innovative_healthcare_technologies_allow_us_to_grasp_the_complexities_and_nuances_of_the_medical_field_read_more {
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.our_comprehensive_knowledge_and_extensive_experience_in_healthcare_as_providers_and_users_of_innovative_healthcare_technologies_allow_us_to_grasp_the_complexities_and_nuances_of_the_medical_field_read_more_span {
  color: var(--text-dark, #536288);
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
}
.our_comprehensive_knowledge_and_extensive_experience_in_healthcare_as_providers_and_users_of_innovative_healthcare_technologies_allow_us_to_grasp_the_complexities_and_nuances_of_the_medical_field_read_more_span2 {
  color: #4eb2f9;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
}
._3 {
  color: rgba(60, 80, 224, 0.03);
  text-align: left;
  font: 700 254px/160% "Overpass-Bold", sans-serif;
  position: absolute;
  left: 290px;
  top: -29px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ellipse_45 {
  background: var(--accents-purple, #a27fff);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 71.29%;
  left: 14.36%;
  width: 14.35%;
  bottom: 73.87%;
  top: 12.06%;
  height: 14.07%;
}
.icon_line_appointment {
  position: absolute;
  right: 74.36%;
  left: 17.43%;
  width: 8.2%;
  bottom: 76.88%;
  top: 15.08%;
  height: 8.04%;
  overflow: hidden;
}
.vector_29 {
  height: auto;
  position: absolute;
  right: 14.58%;
  left: 14.58%;
  width: 70.83%;
  bottom: 16.67%;
  top: 27.08%;
  height: 56.25%;
  overflow: visible;
}
.group4 {
  height: auto;
  position: absolute;
  left: 0px;
  top: 4.14px;
  overflow: visible;
}
.group6 {
  height: auto;
  position: absolute;
  left: 28.38px;
  top: 10.93px;
  overflow: visible;
}
.group8 {
  height: auto;
  position: absolute;
  left: 2.84px;
  top: 11.44px;
  overflow: visible;
}
.we_support_early_stage_med_tech_startups {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(--website-heading-h-4, 500 24px/120% "Inter-Medium", sans-serif);
  position: relative;
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.our_investment_strategy_focuses_primarily_on_pre_seed_and_seed_stages_we_want_to_nuture_new_ideas_and_innovations_in_healthcare_with_investments_ranging_from_50_000_to_100_000_per_deal_we_read_more {
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 278px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.our_investment_strategy_focuses_primarily_on_pre_seed_and_seed_stages_we_want_to_nuture_new_ideas_and_innovations_in_healthcare_with_investments_ranging_from_50_000_to_100_000_per_deal_we_read_more_span {
  color: var(--text-dark, #536288);
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
}
.our_investment_strategy_focuses_primarily_on_pre_seed_and_seed_stages_we_want_to_nuture_new_ideas_and_innovations_in_healthcare_with_investments_ranging_from_50_000_to_100_000_per_deal_we_read_more_span2 {
  color: #4eb2f9;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
}
.frame_10 {
  background: #f5f9fe;
  width: 1440px;
  height: 940px;
  position: absolute;
  left: 0px;
  top: 2754px;
  overflow: hidden;
}
.frame_102 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0px;
  top: 120px;
}
.our_portfolio {
  color: var(--text-darker, #0f2f64);
  text-align: center;
  font: var(--website-heading-h-2, 500 40px/120% "Inter-Medium", sans-serif);
  position: relative;
  width: 505px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news_list {
  flex-shrink: 0;
  width: 1442px;
  height: 558px;
  position: relative;
}
.news_list2 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: -804px;
  top: 0px;
}
.news_1 {
  flex-shrink: 0;
  width: 968px;
  height: 554px;
  position: static;
}
.image2 {
  width: 911px;
  height: 554px;
  position: absolute;
  left: 57px;
  top: 0px;
}
.content2 {
  background: var(--base-white, #ffffff);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 0px;
  top: 280px;
}
.new_york_post_logo {
  flex-shrink: 0;
  width: 177.4px;
  height: 32px;
  position: relative;
}
.image_2_removebg_preview_12 {
  width: 182.75px;
  height: 27.41px;
  position: absolute;
  left: 35.25px;
  top: calc(50% - 13.77px);
}
.image_2_removebg_preview_22 {
  width: 32.58px;
  height: 32.11px;
  position: absolute;
  left: 0px;
  top: calc(50% - 16.11px);
}
.mcd_surgery_cente_the_disruptive_and_modern_healthcare_service_in_this_year {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(--website-heading-h-4, 500 24px/120% "Inter-Medium", sans-serif);
  position: relative;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.action {
  flex-shrink: 0;
  width: 560px;
  height: 26px;
  position: static;
}
.arrow_right {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 568px;
  top: 155px;
  overflow: visible;
}
.meta_data {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 32px;
  top: 154px;
}
._8_january_2022 {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ellipse_3 {
  background: var(--text-light, #8b98b8);
  border-radius: 50%;
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  position: relative;
}
._2_457_reads {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.news_2 {
  flex-shrink: 0;
  width: 1000px;
  height: 558px;
  position: static;
}
.image3 {
  width: 1000px;
  height: 558px;
  position: absolute;
  left: 1992px;
  top: 0px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(-1, 1);
}
.content3 {
  background: var(--base-white, #ffffff);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 1024px;
  top: 290px;
}
.arrow_right2 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 568px;
  top: 155px;
  overflow: visible;
}
._28_july_2023 {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
._2_457_views {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.news_3 {
  flex-shrink: 0;
  width: 1000px;
  height: 558px;
  position: static;
}
.bg2 {
  width: 1000px;
  height: 558px;
  position: absolute;
  left: 2016px;
  top: 0px;
  overflow: visible;
}
.image4 {
  width: 1000px;
  height: 554px;
  position: absolute;
  left: 2016px;
  top: 2px;
}
.content4 {
  background: var(--base-white, #ffffff);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 2048px;
  top: 290px;
}
.arrow_right3 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 568px;
  top: 155px;
  overflow: visible;
}
.nav {
  position: absolute;
  inset: 0;
}
.nav2 {
  width: 56px;
  height: 56px;
  position: static;
}
.ellipse_2 {
  background: var(--brand-primary, #1e5dbc);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 754px;
  top: 814px;
}
.icon_line_arrow_right {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 770px;
  top: 830px;
  overflow: visible;
}
._1_4 {
  text-align: left;
  font: var(
    --website-body-xlarge-regular,
    400 20px/160% "Outfit-Regular",
    sans-serif
  );
  position: absolute;
  left: 704px;
  top: 826px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
._1_4_span {
  color: var(--text-darker, #0f2f64);
  font: var(
    --website-body-xlarge-regular,
    400 20px/160% "Outfit-Regular",
    sans-serif
  );
}
._1_4_span2 {
  color: var(--text-light, #8b98b8);
  font: var(
    --website-body-xlarge-regular,
    400 20px/160% "Outfit-Regular",
    sans-serif
  );
}
.ellipse_22 {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 688px;
  top: 814px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(-1, 1);
}
.icon_line_arrow_right2 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 672px;
  top: 830px;
  transform: translate(-24px, 0px);
  overflow: visible;
}
.frame_11 {
  background: #f5f9fe;
  width: 1440px;
  height: 900px;
  position: absolute;
  left: 0px;
  top: 4639px;
  overflow: hidden;
}
.content5 {
  position: absolute;
  inset: 0;
}
.indicator {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 100.5px;
  top: 746px;
}
.rectangle_64 {
  background: var(--brand-primary, #1e5dbc);
  border-radius: 20px;
  flex-shrink: 0;
  width: 53px;
  height: 6px;
  position: relative;
}
.rectangle_65 {
  background: var(--text-lighter, #e1e3e8);
  border-radius: 20px;
  flex-shrink: 0;
  width: 53px;
  height: 6px;
  position: relative;
}
.rectangle_66 {
  background: var(--text-lighter, #e1e3e8);
  border-radius: 20px;
  flex-shrink: 0;
  width: 53px;
  height: 6px;
  position: relative;
}
.locations {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 100px;
  top: 216px;
}
.cards2 {
  flex-shrink: 0;
  width: 336px;
  height: 461px;
  position: relative;
}
.img {
  width: 336px;
  height: 461px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}
.image_5 {
  width: 654.09px;
  height: 654.09px;
  position: absolute;
  left: -107.14px;
  top: -171.19px;
}
.d_2_wev_i_wqo_wd_4_i_tt_gw_hpg_z_76_qfj_3_vfn_hwl_gn_6_n_ezz_2_acs_lq_b_2_x_5_u_5_rp_dh_afv_a_2_npnq_6_qjj_0_dxnvh_09_i_tv_mmoeeq_fi_o_eg_sj_xp_9_zas_pyw_w_th_4_il_6_b_4_z_8_md_wp_2_pnm_ndv_bg_w_1280 {
  width: 410.9px;
  position: absolute;
  left: calc(50% - 205.45px);
  bottom: 13.18%;
  top: -0.05%;
  height: 86.86%;
}
.text {
  background: var(--base-white, #ffffff);
  border-radius: 0px 2px 0px 0px;
  padding: 24px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 0px;
  bottom: -37px;
}
.anup_pradhan {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(
    --website-body-xlarge-medium,
    500 20px/160% "Outfit-Medium",
    sans-serif
  );
  position: relative;
  width: 232px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav3 {
  flex-shrink: 0;
  width: 232px;
  height: 58px;
  position: relative;
}
.founder_managing_director {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-large-regular,
    400 18px/160% "Outfit-Regular",
    sans-serif
  );
  position: absolute;
  left: 0px;
  top: 0px;
  width: 177px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.icon_line_arrow_right_2 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 208px;
  top: 1px;
  overflow: visible;
}
.image_4 {
  width: 412.35px;
  position: absolute;
  left: calc(50% - 206.18px);
  bottom: -10.93%;
  top: 0%;
  height: 110.93%;
}
.sonya_jagwani {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(
    --website-body-xlarge-medium,
    500 20px/160% "Outfit-Medium",
    sans-serif
  );
  position: relative;
  width: 232px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.partner_chief_medical_officer {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-large-regular,
    400 18px/160% "Outfit-Regular",
    sans-serif
  );
  position: absolute;
  left: 0px;
  top: 0px;
  width: 177px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.icon_line_arrow_right_22 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 208px;
  top: 1px;
  overflow: visible;
}
.image_6 {
  width: 1207.5px;
  position: absolute;
  left: calc(50% - 589.61px);
  bottom: -56.89%;
  top: -17.73%;
  height: 174.62%;
}
.icon_line_arrow_right_23 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 208px;
  top: 1px;
  overflow: visible;
}
.img2 {
  background: #4d6182;
  width: 336px;
  height: 461px;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
}
.image_41_removebg_preview_1 {
  width: 538.35px;
  height: 548.45px;
  position: absolute;
  right: -153.35px;
  top: 0px;
}
.james_sinclair {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(
    --website-body-xlarge-medium,
    500 20px/160% "Outfit-Medium",
    sans-serif
  );
  position: relative;
  width: 232px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.a_leader_in_start_ups_organizations {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-large-regular,
    400 18px/160% "Outfit-Regular",
    sans-serif
  );
  position: absolute;
  left: 0px;
  top: 0px;
  width: 177px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.icon_line_arrow_right_24 {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 208px;
  top: 1px;
  overflow: visible;
}
.title {
  display: flex;
  flex-direction: row;
  gap: 895px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 100px;
  top: 120px;
}
.our_team {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(--website-heading-h-2, 500 40px/120% "Inter-Medium", sans-serif);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav_slider {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.nav4 {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  position: static;
}
.ellipse_23 {
  border-radius: 50%;
  position: absolute;
  right: 12.5%;
  left: 43.75%;
  width: 43.75%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(-1, 1);
}
.icon_line_arrow_right3 {
  height: auto;
  position: absolute;
  right: 50%;
  left: 31.25%;
  width: 18.75%;
  bottom: 28.57%;
  top: 28.57%;
  height: 42.86%;
  transform: translate(-24px, 0px);
  overflow: visible;
}
.ellipse_24 {
  background: var(--brand-primary, #1e5dbc);
  border-radius: 50%;
  position: absolute;
  right: 0%;
  left: 56.25%;
  width: 43.75%;
  bottom: 0%;
  top: 0%;
  height: 100%;
}
.icon_line_arrow_right4 {
  height: auto;
  position: absolute;
  right: 12.5%;
  left: 68.75%;
  width: 18.75%;
  bottom: 28.57%;
  top: 28.57%;
  height: 42.86%;
  overflow: visible;
}
.frame_13 {
  background: #f5f9fe;
  width: 1440px;
  height: 800px;
  position: absolute;
  left: 0px;
  top: 6033px;
  overflow: hidden;
}
.frame_39789 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 100px;
  top: 120px;
}
.img3 {
  flex-shrink: 0;
  width: 609px;
  height: 575px;
  position: relative;
  overflow: hidden;
}
.image5 {
  position: absolute;
  inset: 0;
}
.image6 {
  width: 607.96px;
  height: 574px;
  position: absolute;
  left: 1.04px;
  top: 0px;
}
.business_people_with_digital_tablet_blueprint_1 {
  width: 760.17px;
  height: 1140.11px;
  position: absolute;
  left: 0px;
  top: -336.84px;
}
.next_reading {
  width: 216.53px;
  height: 89px;
  position: static;
}
.rectangle_15 {
  background: var(--base-white, #ffffff);
  width: 216.53px;
  height: 89px;
  position: absolute;
  left: 0px;
  top: 486px;
}
.text2 {
  width: 110.72px;
  height: 26px;
  position: static;
}
.join_us {
  color: var(--brand-primary, #1e5dbc);
  text-align: left;
  font: var(--website-button-normal, 700 16px/160% "Outfit-Bold", sans-serif);
  position: absolute;
  left: calc(50% - 232px);
  top: 517px;
  width: 57.26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.right_24_px {
  width: 24.98px;
  height: 24px;
  position: absolute;
  left: 158.24px;
  top: 518px;
  overflow: visible;
}
.content6 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.title2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.logo2 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.invest_alongside_pradhan_capital {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(--website-heading-h-2, 500 40px/120% "Inter-Medium", sans-serif);
  position: relative;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pradhan_capital_extends_a_unique_opportunity_for_physicians_and_healthcare_providers_to_participate_in_our_investments_and_gain_access_to_private_equity_type_companies_that_may_otherwise_be_challenging_to_reach {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame_39790 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.we_believe_in_the_power_of_collaboration_and_the_immense_value_that_healthcare_professionals_can_bring_to_the_table_as_investors_by_investing_alongside_us_physicians_and_healthcare_providers_can_broaden_their_investment_portfolio_and_contribute_to_the_advancement_of_innovative_healthcare_technologies {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.this_mutually_beneficial_arrangement_fosters_a_dynamic_ecosystem_where_medical_expertise_and_financial_acumen_converge_ultimately_driving_transformative_change_in_the_industry {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.join_us_in_unlocking_new_investment_possibilities_and_shaping_the_future_of_healthcare_together {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame_14 {
  background: #1e5dbc;
  width: 1440px;
  height: 80px;
  position: absolute;
  left: 0px;
  top: 6833px;
  overflow: hidden;
}
.small_copyright_2023_pradhan_capital_llc_all_rights_reserved {
  color: var(--brand-tertiary, #f5f9fe);
  text-align: center;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: absolute;
  left: calc(50% - 208.5px);
  top: calc(50% - 13px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.our_story {
  width: 1440px;
  height: 745px;
  position: absolute;
  left: 0px;
  top: 3794px;
}
.title3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 548px;
  top: 0px;
}
.our_services {
  color: #4eb2f9;
  text-align: left;
  font: 500 16px/120% "Inter-Medium", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.advisory_services {
  color: var(--text-darker, #0f2f64);
  text-align: center;
  font: var(--website-heading-h-2, 500 40px/120% "Inter-Medium", sans-serif);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img4 {
  width: 605px;
  height: 523px;
  position: absolute;
  left: 100px;
  top: 123px;
  overflow: hidden;
}
.image7 {
  width: 605px;
  height: 594.64px;
  position: absolute;
  left: 0px;
  top: -12.05px;
}
.bg3 {
  background: var(--base-white, #ffffff);
  width: 358.44px;
  height: 207.19px;
  position: absolute;
  left: 246.42px;
  top: 322.56px;
}
.statistics {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 384px;
  top: 486px;
}
.statistics2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.icon3 {
  background: var(--accents-purple, #a27fff);
  border-radius: 132px;
  opacity: 0.30000001192092896;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.icon_line_team {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 16px;
  overflow: hidden;
}
.rectangle_34 {
  height: auto;
  position: absolute;
  right: 39.58%;
  left: 39.58%;
  width: 20.83%;
  bottom: 52.08%;
  top: 35.42%;
  height: 12.5%;
  overflow: visible;
}
.rectangle_33 {
  height: auto;
  position: absolute;
  right: 37.5%;
  left: 37.5%;
  width: 25%;
  bottom: 75%;
  top: 12.5%;
  height: 12.5%;
  overflow: visible;
}
.rectangle_32 {
  height: auto;
  position: absolute;
  right: 37.5%;
  left: 37.5%;
  width: 25%;
  bottom: 12.5%;
  top: 62.5%;
  height: 25%;
  overflow: visible;
}
.group10 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 10.55%;
  top: 10.55%;
  height: 78.91%;
  overflow: visible;
}
.stat {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
._90_k {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(--website-heading-h-4, 500 24px/120% "Inter-Medium", sans-serif);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.professional_medical_team {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 137px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.icon4 {
  background: var(--accents-green, #1ebcb4);
  border-radius: 132px;
  opacity: 0.30000001192092896;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.icon_line_medical_tech {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 16px;
  overflow: hidden;
}
.group11 {
  height: auto;
  position: absolute;
  right: 8.33%;
  left: 8.33%;
  width: 83.33%;
  bottom: 13.22%;
  top: 16.67%;
  height: 70.12%;
  overflow: visible;
}
.icon5 {
  background: var(--accents-yellow, #ffb201);
  border-radius: 132px;
  opacity: 0.30000001192092896;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.icon_line_location {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 16px;
  overflow: hidden;
}
.icon6 {
  height: auto;
  position: absolute;
  left: 2px;
  top: 2px;
  overflow: visible;
}
.icon7 {
  background: var(--brand-secondary, #4eb2f9);
  border-radius: 132px;
  opacity: 0.30000001192092896;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.icon_line_patients {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 16px;
  overflow: hidden;
}
.ellipse_48 {
  background: var(--base-white, #ffffff);
  border-radius: 50%;
  position: absolute;
  right: 41.67%;
  left: 25%;
  width: 33.33%;
  bottom: 45.83%;
  top: 25%;
  height: 29.17%;
}
.group12 {
  height: auto;
  position: absolute;
  right: 8.33%;
  left: 8.33%;
  width: 83.33%;
  bottom: 8.33%;
  top: 8.33%;
  height: 83.33%;
  overflow: visible;
}
.frame_112 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 564px;
  position: absolute;
  left: 737px;
  top: 123px;
}
.pradhan_capital_is_proud_to_offer_comprehensive_advisory_services_to_med_tech_startups {
  color: #4eb2f9;
  text-align: left;
  font: 500 24px/36px "Inter-Medium", sans-serif;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.our_team_is_equipped_to_provide_valuable_expertise_and_guidance_in_various_capacities_including_serving_as_scientific_advisors_board_members_and_offering_business_advisory_support {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.with_a_deep_understanding_of_the_healthcare_industry_and_a_network_of_experienced_professionals_we_are_committed_to_helping_startups_navigate_challenges_make_informed_decisions_and_maximize_their_growth_potential_whether_you_need_scientific_insights_strategic_direction_or_operational_guidance_pradhan_capital_is_dedicated_to_partnering_with_you_every_step_of_the_way {
  color: var(--text-dark, #536288);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 571px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame_9 {
  background: #4eb2f9;
  width: 1240px;
  height: 334px;
  position: absolute;
  left: 100px;
  top: 860px;
  overflow: hidden;
}
.frame_8 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 350.5px);
  top: calc(50% - 127px);
}
.frame_7 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_12 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.mask_group2 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: static;
}
.ellipse_4432 {
  background: #c4c4c4;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 49px;
  top: 0px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.group_397862 {
  width: 50.39px;
  height: 50.39px;
  position: static;
}
.ellipse_4442 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 19.91px;
  height: 19.91px;
  position: absolute;
  left: 42.33px;
  top: -7.63px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4452 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 19.91px;
  height: 19.91px;
  position: absolute;
  left: 53.75px;
  top: 20.63px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4462 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 19.91px;
  height: 19.91px;
  position: absolute;
  left: 33.91px;
  top: 12.21px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4472 {
  background: #1e5dbc;
  border-radius: 50%;
  width: 19.91px;
  height: 19.91px;
  position: absolute;
  left: 61.91px;
  top: 0.14px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.pradhan_capital2 {
  color: #ffffff;
  text-align: left;
  font: 500 16px/120% "Inter-Medium", sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.empowering_healthcare_innovation_through_frontline_experience {
  color: #ffffff;
  text-align: center;
  font: 500 40px/120% "Inter-Medium", sans-serif;
  position: relative;
  width: 701px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mask_group3 {
  opacity: 0.20000000298023224;
  position: absolute;
  inset: 0;
}
.ellipse_4433 {
  background: #c4c4c4;
  border-radius: 50%;
  width: 267px;
  height: 267px;
  position: absolute;
  left: 1015px;
  top: -52px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.group_397863 {
  width: 560.61px;
  height: 560.63px;
  position: static;
}
.ellipse_4443 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 940.82px;
  top: -136.91px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4453 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 1067.87px;
  top: 177.5px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4463 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 847.14px;
  top: 83.81px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4473 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 1158.62px;
  top: -50.42px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4434 {
  background: #c4c4c4;
  border-radius: 50%;
  width: 267px;
  height: 267px;
  position: absolute;
  left: -40px;
  top: 107px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.ellipse_4444 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: -114.18px;
  top: 22.09px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4454 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 12.87px;
  top: 336.5px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4464 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: -207.86px;
  top: 242.81px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4474 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 103.62px;
  top: 108.58px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.frame_122 {
  background: #4eb2f9;
  width: 1240px;
  height: 334px;
  position: absolute;
  left: calc(50% - 620px);
  top: 5619px;
  overflow: hidden;
}
.frame_82 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 350.5px);
  top: calc(50% - 143px);
}
.frame_123 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame_39788 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.apply_to_join_our_portfolio {
  color: #ffffff;
  text-align: center;
  font: 500 40px/120% "Inter-Medium", sans-serif;
  position: relative;
  width: 701px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.if_your_startup_shares_our_vision_of_improving_healthcare_through_innovation_we_invite_you_to_connect_with_us_together_let_s_explore_how_pradhan_capital_can_contribute_to_the_growth_and_success_of_your_med_tech_venture {
  color: var(--brand-tertiary, #f5f9fe);
  text-align: center;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 701px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.core_value {
  width: 1440px;
  height: 640px;
  position: absolute;
  left: 0px;
  top: 2114px;
}
.core_values {
  padding: 0px 0px 0px 100px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
  height: 640px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.frame_132 {
  flex-shrink: 0;
  width: 608px;
  height: 642px;
  position: relative;
  overflow: hidden;
}
.title4 {
  position: absolute;
  inset: 0;
}
.bg4 {
  background: var(--brand-secondary, #4eb2f9);
  width: 608px;
  height: 640px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.title5 {
  background: var(--brand-secondary, #4eb2f9);
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 108px;
  top: calc(50% - 152px);
}
.our_core_values_that_drive_us_everyday {
  color: var(--base-white, #ffffff);
  text-align: left;
  font: var(--website-heading-h-2, 500 40px/120% "Inter-Medium", sans-serif);
  position: relative;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.we_believe_that_improving_healthcare_doesn_t_just_change_lives_it_changes_the_world_creating_an_equitable_health_system_starts_with_bringing_diverse_experiences_backgrounds_and_perspectives_together {
  color: var(--brand-tertiary, #f5f9fe);
  text-align: left;
  font: var(
    --website-body-normal-regular,
    400 16px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mask_group4 {
  opacity: 0.20000000298023224;
  width: 267px;
  height: 267px;
  position: static;
}
.ellipse_4435 {
  background: #c4c4c4;
  border-radius: 50%;
  width: 267px;
  height: 267px;
  position: absolute;
  left: 320px;
  top: 426px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}
.ellipse_4445 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 245.82px;
  top: 341.09px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4455 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 372.87px;
  top: 655.5px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4465 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 152.14px;
  top: 561.81px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.ellipse_4475 {
  background: #ffffff;
  border-radius: 50%;
  width: 221.52px;
  height: 221.52px;
  position: absolute;
  left: 463.62px;
  top: 427.58px;
  transform-origin: 0 0;
  transform: rotate(-22deg) scale(1, 1);
}
.points {
  display: flex;
  flex-direction: row;
  gap: 55px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.column_1 {
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.core_values2 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.ellipse_46 {
  background: var(--accents-yellow, #ffb201);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 78.21%;
  left: 0%;
  width: 21.79%;
  bottom: 6.25%;
  top: 6.25%;
  height: 87.5%;
}
.icon_line_safe {
  position: absolute;
  right: 82.88%;
  left: 4.67%;
  width: 12.45%;
  bottom: 25%;
  top: 25%;
  height: 50%;
  overflow: hidden;
}
.group_25 {
  height: auto;
  position: absolute;
  left: 2.67px;
  top: 2.67px;
  overflow: visible;
}
.keep_healtcare_human {
  color: var(--text-darker, #0f2f64);
  text-align: left;
  font: var(
    --website-body-xlarge-regular,
    400 20px/160% "Outfit-Regular",
    sans-serif
  );
  position: relative;
  width: 185px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ellipse_47 {
  background: var(--accents-green, #1ebcb4);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 78.21%;
  left: 0%;
  width: 21.79%;
  bottom: 6.25%;
  top: 6.25%;
  height: 87.5%;
}
.icon_line_healthcare {
  position: absolute;
  right: 82.88%;
  left: 4.67%;
  width: 12.45%;
  bottom: 25%;
  top: 25%;
  height: 50%;
  overflow: hidden;
}
.group15 {
  position: absolute;
  inset: 0;
}
.vector21 {
  height: auto;
  position: absolute;
  right: 8.33%;
  left: 8.33%;
  width: 83.33%;
  bottom: 16.31%;
  top: 44.98%;
  height: 38.72%;
  overflow: visible;
}
.ellipse_49 {
  background: var(--base-white, #ffffff);
  border-radius: 50%;
  position: absolute;
  right: 20.83%;
  left: 54.17%;
  width: 25%;
  bottom: 58.33%;
  top: 16.67%;
  height: 25%;
}
.vector22 {
  height: auto;
  position: absolute;
  right: 16.76%;
  left: 49.33%;
  width: 33.91%;
  bottom: 53.59%;
  top: 12.5%;
  height: 33.91%;
  overflow: visible;
}
.group_262 {
  width: 5.33px;
  height: 5.33px;
  position: absolute;
  left: 18.67px;
  top: 6.67px;
  overflow: visible;
}
.ellipse_410 {
  background: var(--accents-purple, #a27fff);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 78.21%;
  left: 0%;
  width: 21.79%;
  bottom: 6.25%;
  top: 6.25%;
  height: 87.5%;
}
.icon_line_discipline {
  position: absolute;
  right: 82.88%;
  left: 4.67%;
  width: 12.45%;
  bottom: 25%;
  top: 25%;
  height: 50%;
  overflow: hidden;
}
.ellipse_50 {
  height: auto;
  position: absolute;
  right: 16.67%;
  left: 45.83%;
  width: 37.5%;
  bottom: 50%;
  top: 12.5%;
  height: 37.5%;
  overflow: visible;
}
.group16 {
  height: auto;
  position: absolute;
  right: 13.31%;
  left: 12.5%;
  width: 74.19%;
  bottom: 8.33%;
  top: 8.33%;
  height: 83.33%;
  overflow: visible;
}
.column_2 {
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_411 {
  background: var(--brand-secondary, #4eb2f9);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 78.21%;
  left: 0%;
  width: 21.79%;
  bottom: 6.25%;
  top: 6.25%;
  height: 87.5%;
}
.icon_line_procedures {
  height: auto;
  position: absolute;
  right: 82.88%;
  left: 4.67%;
  width: 12.45%;
  bottom: 25%;
  top: 25%;
  height: 50%;
  overflow: visible;
}
.ellipse_412 {
  background: var(--brand-primary, #1e5dbc);
  border-radius: 50%;
  opacity: 0.30000001192092896;
  position: absolute;
  right: 78.21%;
  left: 0%;
  width: 21.79%;
  bottom: 6.25%;
  top: 6.25%;
  height: 87.5%;
}
.icon_line_stay_for_everyone {
  position: absolute;
  right: 82.88%;
  left: 4.67%;
  width: 12.45%;
  bottom: 25%;
  top: 25%;
  height: 50%;
  overflow: hidden;
}
.group17 {
  height: auto;
  position: absolute;
  right: 8.33%;
  left: 8.33%;
  width: 83.33%;
  bottom: 8.33%;
  top: 8.33%;
  height: 83.33%;
  overflow: visible;
}
