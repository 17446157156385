
      a,
      button,
      input,
      select,
      h1,
      h2,
      h3,
      h4,
      h5,
      * {
        margin: 0;
        padding: 0;
        border: none;
        text-decoration: none;
        appearance: none;
        background: none;

        -webkit-font-smoothing: antialiased;
      }

      

.input_content {
  display: flex;
  flex-direction: column;
  gap: 1px;
  height: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin-top: 10px;
  position: relative;
  outline: none;
  font-size:medium;
}
.input_content:hover{
  outline: none;
 }



.button_panel {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.button_frame {
  background: #7654e8;
  border-radius: 8px;
  padding: 12px 40px 12px 40px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 43px;
  position: relative;
  cursor:pointer;
}
.button_text {
  color: #ffffff;
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}

.forgot_password,
.forgot_password * {
  box-sizing: border-box;
}
.forgot_password {
  background: linear-gradient(
      108.63deg,
      rgba(101, 101, 209, 0.6) 0%,
      rgba(24, 61, 186, 0.3) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
  border-radius: 12px;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.decorations {
  position: absolute;
  inset: 0;
}
.polygon_1 {
  width: 26.82px;
  height: 26.82px;
  position: absolute;
  left: 62.99px;
  top: 370px;
  transform: translate(-8.27px, -0.01px);
  overflow: visible;
}
.polygon_3 {
  width: 41.66px;
  height: 37.72px;
  position: absolute;
  left: 226px;
  top: 727.51px;
  transform: translate(7.81px, -10.75px);
  overflow: visible;
}
.polygon_2 {
  width: 26.82px;
  height: 26.82px;
  position: absolute;
  left: 1235.17px;
  top: 251.78px;
  transform: translate(1.61px, -13.3px);
  overflow: visible;
}
.polygon_4 {
  width: 26.82px;
  height: 26.82px;
  position: absolute;
  left: 1187.19px;
  top: 561px;
  transform: translate(-10.13px, -5.41px);
  overflow: visible;
}
.star_1 {
  border-radius: 0px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 210.9px;
  top: 265.33px;
  transform: translate(0.44px, -6.99px);
  overflow: visible;
}
.star_2 {
  border-radius: 0px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 1183.18px;
  top: 125.68px;
  transform: translate(-5.95px, -0.45px);
  overflow: visible;
}
.line_2 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 86px;
  top: 545.52px;
  transform: translate(-4.59px, -24.43px);
  overflow: visible;
}
.line_3 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 1126.92px;
  top: 381.39px;
  transform: translate(-4.13px, -6px);
  overflow: visible;
}
.line_4 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 531.85px;
  top: 752.23px;
  transform: translate(-4.31px, -5.98px);
  overflow: visible;
}
.ellipse_19 {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 127px;
  top: 715px;
  filter: blur(2px);
}
.ellipse_20 {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 1311px;
  top: 390px;
  filter: blur(2px);
}
.line_5 {
  width: 63px;
  height: 5px;
  position: absolute;
  left: 233px;
  top: 61.52px;
  transform: translate(-4.59px, -24.43px);
  overflow: visible;
}
.star_3 {
  border-radius: 0px;
  width: 23px;
  height: 23px;
  position: absolute;
  left: 518.04px;
  top: 23.98px;
  transform: translate(-1.67px, -4.61px);
  overflow: visible;
}
.ellipse_21 {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 814px;
  top: 71px;
  filter: blur(2px);
}
.frame_79 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 1150.38px;
  top: 642px;
  transform-origin: 0 0;
  transform: rotate(45deg) scale(1, 1);
  filter: blur(4.5px);
}
.frame_74 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_12 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_18 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_17 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_16 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_15 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_14 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.ellipse_13 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  width: 7px;
  height: 7px;
  position: relative;
}
.frame_75 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_76 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_77 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_78 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_792 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_80 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_81 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_782 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 130.19px;
  top: 150.06px;
  transform-origin: 0 0;
  transform: rotate(54.699deg) scale(1, 1);
  filter: blur(2px);
}
.ellipse_122 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_182 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_172 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_162 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_152 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_142 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.ellipse_132 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 50%;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  position: relative;
}
.forgot_password2 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  width: 400px;
  position: absolute;
  left: calc(50% - 200px);
  top: calc(50% - 331px);
  box-shadow: var(
    --shadowchat-box-shadow,
    0px 0px 24px 2px rgba(0, 0, 0, 0.08),
    0px 4px 16px 0px rgba(0, 0, 0, 0.08)
  );
}
.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_354 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.logo {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.logo_icon {
  background: linear-gradient(to left, #262626, #262626);
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.brand_name {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.title {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 32px "ProductSans-Bold", sans-serif;
  position: relative;
}
.description {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.text {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.button {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.text_wrapper {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.text2 {
  color: var(--button-link-link-default, #2d85f0);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  text-decoration: underline;
  position: relative;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.main {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.field {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: var(--corners-input, 12px);
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 56px;
  position: relative;
  overflow: hidden;
}
.input_content {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.search {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: var(--body, 400 16px/24px "Poppins-Regular", sans-serif);
  position: relative;
  align-self: stretch;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.button2 {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 44px;
  position: relative;
}
.text3 {
  color: var(--text-on-invert-primary, #ffffff);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.continue_with {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.navlink {
  color: #2a79ff;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  text-decoration: underline;
  position: relative;
  cursor: pointer;
}
.line_54 {
  border-style: solid;
  border-color: var(--text-on-surface-secondary, #737373);
  border-width: 1px 0 0 0;
  flex: 1;
  height: 0px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(-180deg) scale(1, 1);
}
.or_continue_with {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: var(
    --text-primary-regular,
    400 16px/24px "Poppins-Regular",
    sans-serif
  );
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.line_55 {
  border-style: solid;
  border-color: var(--text-on-surface-secondary, #737373);
  border-width: 1px 0 0 0;
  flex: 1;
  height: 0px;
  position: relative;
  transform-origin: 0 0;
  transform: rotate(-180deg) scale(1, 1);
}
.sign_up_in_with {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sign_in_with_google {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 48px;
  position: relative;
}
.image_10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.image_102 {
  flex-shrink: 0;
  width: 20px;
  height: 20.47px;
  position: relative;
}
.sign_in_with_google2 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.sign_in_with_outlook {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 48px;
  position: relative;
}
.image_11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.image_112 {
  flex-shrink: 0;
  width: 24px;
  height: 22px;
  position: relative;
}
.sign_in_with_outlook2 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.sign_in_with_facebook {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 48px;
  position: relative;
}
.image_12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.image_122 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.sign_in_with_facebook2 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
