
      a,
      button,
      input,
      select,
      h1,
      h2,
      h3,
      h4,
      h5,
      * {
        margin: 0;
        padding: 0;
        border: none;
        text-decoration: none;
        appearance: none;
        background: none;

        -webkit-font-smoothing: antialiased;
      }
    
._404_page,
._404_page * {
  box-sizing: border-box;
}
._404_page {
  background: var(--sufrace-surface-2, #fafafa);
  border-radius: 12px;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.error_pop_up {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 40px 32px 40px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 400px;
  position: absolute;
  left: calc(50% - 200px);
  top: calc(50% - 176.04px);
  box-shadow: 0px 0px 112px 2px rgba(0, 0, 0, 0.08),
    0px 4px 48px 0px rgba(0, 0, 0, 0.04);
}
.illustration {
  flex-shrink: 0;
  width: 93.01px;
  height: 98.07px;
  position: static;
}
.shadow {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  width: 83px;
  height: 17px;
  position: absolute;
  left: 158.75px;
  top: 121.07px;
  filter: blur(10px);
}
.content {
  background: #f1eefd;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 167.79px;
  top: 40px;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  transform-origin: 0 0;
  transform: rotate(10.296deg) scale(1, 1);
}
.sentiment_very_dissatisfied {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  position: relative;
  overflow: visible;
}
.content2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.title {
  color: var(--text-on-surface-primary, #262626);
  text-align: center;
  font: 700 24px "ProductSans-Bold", sans-serif;
  position: relative;
  align-self: stretch;
}
.text {
  color: var(--text-on-surface-primary, #262626);
  text-align: center;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.button {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 43px;
  position: relative;
}
.text2 {
  color: var(--text-on-invert-primary, #ffffff);
  text-align: left;
  font: var(--button-large, 600 14px "Poppins-SemiBold", sans-serif);
  position: relative;
}
.header {
  background: var(--sufrace-surface-1, #ffffff);
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 0px 0px 1px 0px;
  padding: 0px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  width: 1400px;
  height: 64px;
  position: absolute;
  left: calc(50% - 700px);
  top: 0px;
  backdrop-filter: blur(8px);
}
.logo {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 225px;
  position: relative;
}
.logo_icon {
  background: linear-gradient(to left, #262626, #262626);
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.brand_name {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 600 16px "Montserrat-SemiBold", sans-serif;
  position: relative;
}
.breadcrumbs {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.step_1 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.number_of_the_step {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
}
.number {
  color: var(--text-on-invert-primary, #ffffff);
  text-align: left;
  font: var(--caption-regular, 400 12px "Inter-Regular", sans-serif);
  position: relative;
}
.text3 {
  color: var(--text-on-surface-accent, #9176ed);
  text-align: left;
  font: var(--body-small-regular, 400 14px/20px "Poppins-Regular", sans-serif);
  position: relative;
}
.chevron_right {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.step_2 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.number_of_the_step2 {
  background: var(--text-on-surface-placeholder, #d4d4d4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
}
.text4 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: var(--body-small-regular, 400 14px/20px "Poppins-Regular", sans-serif);
  position: relative;
}
.chevron_right2 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.step_3 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.actions {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.language_selection {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.language {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.language3 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.account {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.avatar {
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.chevron_down {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.help_resources {
  width: 44px;
  height: 44px;
  position: absolute;
  left: 1316px;
  top: 769px;
  overflow: hidden;
}
.hint {
  position: absolute;
  inset: 0;
}
.text_wrapper {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -78px;
  top: -44px;
}
.text5 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.polygon_7 {
  border-radius: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 30px;
  top: -4px;
  overflow: visible;
}
.button2 {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 22px);
  top: calc(50% - 22px);
}
.question_mark_cr_fr {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
