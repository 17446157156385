
      a,
      button,
      input,
      select,
      h1,
      h2,
      h3,
      h4,
      h5,
      * {
        margin: 0;
        padding: 0;
        border: none;
        text-decoration: none;
        appearance: none;
        background: none;

        -webkit-font-smoothing: antialiased;
      }
    
.dashboard,
.dashboard * {
  box-sizing: border-box;
}
.dashboard {
  background: var(--sufrace-surface-2, #fafafa);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 853px;
  position: relative;
  overflow: hidden;
}
.header {
  background: var(--sufrace-surface-1, #ffffff);
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 0px 0px 1px 0px;
  padding: 0px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 1400px;
  height: 64px;
  position: relative;
}
.frame_98 {
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 798px;
  position: relative;
}
.frame_20 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_10 {
  background: linear-gradient(to left, #262626, #262626);
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.ai_builder {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 600 16px "Montserrat-SemiBold", sans-serif;
  position: relative;
}
.menu_items {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.meni_item_active {
  border-style: solid;
  border-color: var(--text-on-surface-accent, #9176ed);
  border-width: 0px 0px 2px 0px;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.dashboard2 {
  color: var(--text-on-surface-accent, #9176ed);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.menu_item {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.text {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_2608569 {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_2608568 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.language {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.english {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_96 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_22 {
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.account {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.chevron_down {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_293 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  top: 64px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.nav_menu {
  background: var(--sufrace-surface-1, #ffffff);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 342px;
  position: relative;
}
.menu {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.menu2 {
  border-radius: 8px;
  padding: 12px 14px 12px 14px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.remix_icons_line_system_arrow_left_line {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
}
.group {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.all_sites {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_2608579 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_2608576 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sidemenu_item {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 310px;
  height: 44px;
  position: relative;
}
.frame_100 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.line_02_up {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.title {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_121 {
  background: var(--sufrace-surface-tertiary, #f6e0b8);
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.pro_plan {
  color: var(--text-on-surface-on-tertiary, #a36b00);
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.sidemenu_item2 {
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 310px;
  height: 44px;
  position: relative;
}
.folder_user {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.megaphone {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.bank_card {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_2608578 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.line_03_up {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_2608577 {
  padding: 0px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sidemenu_item3 {
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 44px;
  position: relative;
}
.plus {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.plus2 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.plus3 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.plus4 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.map {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_2608580 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  position: relative;
}
.upgrade_message {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 314px;
  position: relative;
}
.frame_227 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.txt {
  display: flex;
  flex-direction: column;
  gap: 11px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.txt2 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.lightning {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.upgrade_your_plan {
  color: var(--text-on-surface-accent, #9176ed);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.get_more_benefits_for_your_projects {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
  align-self: stretch;
}
.action {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.dismiss {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.upgrade {
  color: var(--text-on-surface-accent, #9176ed);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.frame_292 {
  padding: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.frame_110 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 44px;
  position: relative;
}
.frame_109 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.karate_school {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 24px "ProductSans-Bold", sans-serif;
  position: relative;
}
.remix_icons_line_system_arrow_right_up_line {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}
.group2 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.frame_168 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.button {
  background: var(--button-secondary-secondary-default, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.share {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.accept_all_cockies {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.edit {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.button2 {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.rocket_launch {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.accept_all_cockies2 {
  color: var(--text-on-invert-primary, #ffffff);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.chevron_down2 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_291 {
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.cintent {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.stats {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 334px;
  height: 168.14px;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
  overflow: hidden;
}
.frame_147 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.users {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 20px "ProductSans-Bold", sans-serif;
  position: relative;
}
.last_7_days {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.vector_3 {
  align-self: stretch;
  flex-shrink: 0;
  height: 44px;
  position: relative;
  overflow: visible;
}
.frame_267 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.view_all_activity {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.arrow_forward {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.vector_32 {
  align-self: stretch;
  flex-shrink: 0;
  height: 44px;
  position: relative;
  overflow: visible;
}
.arrow_forward3 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.vector_33 {
  align-self: stretch;
  flex-shrink: 0;
  height: 44px;
  position: relative;
  overflow: visible;
}
.arrow_forward5 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_134 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: var(
    --shadow-box-shadow,
    0px 12px 16px -4px rgba(11, 93, 153, 0.02),
    0px 6px 6px -2px rgba(0, 0, 0, 0.02)
  );
}
.frame_143 {
  padding: 0px 24px 0px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_144 {
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 0px 0px 1px 0px;
  padding: 24px 0px 24px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
}
.frame_289 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.world_visitors {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 20px "ProductSans-Bold", sans-serif;
  position: relative;
}
.button3 {
  background: var(--button-secondary-secondary-hover, rgba(118, 84, 232, 0.1));
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border-accent, #9176ed);
  border-width: 1px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.plus5 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_167 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.share2 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.file_download_in_lc {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_142 {
  padding: 24px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_135 {
  flex-shrink: 0;
  width: 594.27px;
  height: 334.5px;
  position: relative;
}
.g_10 {
  height: auto;
  position: absolute;
  left: 0px;
  top: 334.5px;
  transform: translate(0px, -334.5px);
  overflow: visible;
}
.group_12 {
  position: absolute;
  inset: 0;
}
.ellipse_24 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 50%;
  width: 4.2px;
  height: 4.2px;
  position: absolute;
  left: 263.04px;
  top: 113.4px;
}
.ellipse_25 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 262.14px;
  top: 112.5px;
}
.group_5 {
  position: absolute;
  inset: 0;
}
.ellipse_242 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 156.64px;
  top: 129px;
}
.ellipse_252 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 155.14px;
  top: 127.5px;
}
.group_4 {
  position: absolute;
  inset: 0;
}
.ellipse_243 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 273.64px;
  top: 114px;
}
.ellipse_253 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 272.14px;
  top: 112.5px;
}
.image_1 {
  width: 19px;
  height: 26px;
  position: absolute;
  left: 271.14px;
  top: 116.5px;
}
.map2 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 256.13px;
  top: 98.5px;
}
.circle {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 28px;
  opacity: 0.10000000149011612;
  width: 40px;
  height: 40px;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}
.circle2 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 28px;
  opacity: 0.20000000298023224;
  width: 24px;
  height: 24px;
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
}
.circle3 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 28px;
  width: 8px;
  height: 8px;
  position: absolute;
  left: calc(50% - 4px);
  top: calc(50% - 4px);
}
.map3 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 483.13px;
  top: 40.5px;
}
.map4 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 483.13px;
  top: 222.5px;
}
.map5 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 139.13px;
  top: 112.5px;
}
.map6 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 162.13px;
  top: 257.5px;
}
.map7 {
  border-radius: 28px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 29.13px;
  top: 17.5px;
}
.group_13 {
  position: absolute;
  inset: 0;
}
.union2 {
  width: 152px;
  height: 79.75px;
  position: absolute;
  left: 203.14px;
  top: 34.5px;
  overflow: visible;
}
.frame_146 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 219.14px;
  top: 50.5px;
}
.ellipse_23 {
  border-radius: 50%;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.frame_145 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.london_uk {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
._523_users {
  color: #424242;
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_136 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
._8_8_k {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 40px "ProductSans-Bold", sans-serif;
  position: relative;
}
.frame_141 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_137 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_138 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.united_states {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_140 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_139 {
  flex-shrink: 0;
  width: 164px;
  height: 6px;
  position: relative;
}
.rectangle_28 {
  background: var(--sufrace-border, #e5e5e5);
  border-radius: 8px;
  width: 164px;
  height: 6px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.rectangle_27 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 8px;
  width: 100px;
  height: 6px;
  position: absolute;
  left: 0px;
  top: 0px;
}
._55 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1382 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.canada {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.rectangle_272 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 8px;
  width: 76px;
  height: 6px;
  position: absolute;
  left: -0.27px;
  top: 0.5px;
}
._45 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1392 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.australia {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.rectangle_273 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 8px;
  width: 51px;
  height: 6px;
  position: absolute;
  left: -0.14px;
  top: 0.36px;
}
._25 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1402 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.united_kingdom {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.rectangle_274 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 8px;
  width: 34px;
  height: 6px;
  position: absolute;
  left: -0.27px;
  top: 0.5px;
}
._15 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_1412 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ireland {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.rectangle_275 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 8px;
  width: 20px;
  height: 6px;
  position: absolute;
  left: -0.27px;
  top: 0.5px;
}
._10 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.range_calendar {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: absolute;
  left: 525px;
  top: 411px;
  box-shadow: var(--shadows-box-shadow, 0px 8px 32px 0px rgba(0, 0, 0, 0.05));
}
.start_month {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.month_header {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 224px;
  position: relative;
}
.buttons {
  padding: 0px 4px 0px 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  position: relative;
}
.icon_button {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 10px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  opacity: 0.5;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  position: relative;
  box-shadow: var(--shadow-sm-box-shadow, 0px 1px 2px 0px rgba(0, 0, 0, 0.05));
}
.caret_left {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.blank {
  flex: 1;
  height: 28px;
  position: relative;
  overflow: hidden;
}
.month {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 224px;
  height: 28px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.june_2023 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 500 14px/20px "Inter-Medium", sans-serif;
  position: relative;
}
.day_picker {
  padding: 16px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.week_header {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.week_day {
  padding: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 32px;
  position: relative;
}
.su {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 12.8px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.mo {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 12.8px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.tu {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 12.8px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.we {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 12.8px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.th {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 12.8px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.fr {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 12.8px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.sa {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 12.8px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.container {
  padding: 8px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.range_week_days {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.range_day {
  border-radius: 6px;
  opacity: 0.5;
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
}
.button4 {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0px;
  top: 0px;
}
._30 {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 14px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.range_day2 {
  border-radius: 6px;
  opacity: 0.5;
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.range_day3 {
  border-radius: 10px;
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.button5 {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0px;
  top: 0px;
}
._302 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 14px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.range_day4 {
  border-radius: 10px;
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
}
.range_day5 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 6px 0px 0px 6px;
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.button6 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0px;
  top: 0px;
}
._303 {
  color: var(--sufrace-surface-1, #ffffff);
  text-align: left;
  font: 400 14px/20px "Inter-Regular", sans-serif;
  position: relative;
}
.range_day6 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.range_day7 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
}
.chevron_right {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.range_day8 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 0px 6px 6px 0px;
  padding: 10px;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
}
.help_resources {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  position: absolute;
  left: 1316px;
  top: 769px;
  overflow: hidden;
}
.group_16 {
  position: absolute;
  inset: 0;
}
.overlay {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -78px;
  top: -44px;
}
.help_resources2 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.polygon_7 {
  border-radius: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 30px;
  top: -4px;
  overflow: visible;
}
.button7 {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 22px);
  top: calc(50% - 22px);
}
.question_mark_cr_fr {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
