.editor_container{
    margin:0;
    padding:0;
    display: flex;
    /*border: 2px solid #6c4ade;*/
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    background: linear-gradient(
      108.63deg,
      rgba(101, 101, 209, 0.6) 0%,
      rgba(24, 61, 186, 0.3) 100%
    ),
    linear-gradient(to left, #ffffff, #ffffff);
      border-radius: 12px;

      position: relative;
      overflow: hidden;

}


.editor_menu{
    /*border: 2px double black;*/
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.editor_panel{
    display: flex;
    justify-items: flex-end;
    justify-content: center;

    position: relative;
    border: 2px solid #6c4ade;
    width: 100%;
    height: 100%;
}


.editor_menu_panel {
  background: #ffffff;
  border-radius: 1px;
  
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 0px;
  padding-top: 10px;
  padding-bottom: 20px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  overflow: hidden;
  }
.editor_menu_items_contianer {
  display: flex;
  flex-direction: row;
  height: 37px;
  border-radius:12px;
}

.editor_menu_item.active .icon_fill{
  fill:  white;
}

.editor_menu_item .icon_fill{
  fill:  #6c4ade;
}

.editor_menu_item{
  border-style: solid;
  color:#000000;
  border-color: #6c4ade; 
  padding: 6px 16px 6px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;

}

.editor_menu_item.desktop {
  border-width: 2px 1px 2px 2px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.editor_menu_item.tablet {
  border-width: 2px 1px 2px 0px;
}

.editor_menu_item.phone {
  border-width: 2px 2px 2px 0px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.editor_menu_item.active {
  color: #ffffff;
  background: #6c4ade;
  cursor:vertical-text;
}

.desktop_dimensions{
  width:98%;
  height:1080px;
}

.tablet_dimensions{
  width:820px;
  height:1180px;
}

.phone_dimensions{
  width:390px;
  height:844px;
}

.hover_icon{
  cursor:pointer;
  
}

.laptop_mac {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.desktop {
  text-align: left;
  font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
}

.tablet_mac {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.tablet {
  text-align: left;
  font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
}

.smartphone {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.mobile {
  text-align: left;
  font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
}
/****************/
  
  .created,
  .created * {
    box-sizing: border-box;
  }
  .created {
    background: #f8f8fc;
    border-radius: 12px;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  .header {
    background: #ffffff;
    border-style: solid;
    border-color: #ebebeb;
    border-width: 1px;
    padding: 16px 40px 16px 40px;
    display: flex;
    flex-direction: row;
    gap: 983px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 64px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .action {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .frame-22 {
    background: #ffffff;
    border-radius: 8px;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 1px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }
  .remix-icons-line-system-eye-line {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
  }
  .group {
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
  }
  .preview {
    color: #000000;
    text-align: left;
    font: 700 16px "ProductSans-Bold", sans-serif;
    position: relative;
  }
  .frame-24 {
    background: #ffffff;
    border-radius: 8px;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 1px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }
  .remix-icons-line-device-save-2-line {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
  }
  .group2 {
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
  }
  .save {
    color: #000000;
    text-align: left;
    font: 700 16px "ProductSans-Bold", sans-serif;
    position: relative;
  }
  .frame-167 {
    background: #6c4ade;
    border-radius: 8px;
    padding: 12px 12px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .rocket-launch {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    overflow: visible;
  }
  .publish {
    color: #ffffff;
    text-align: left;
    font: 700 14px "ProductSans-Bold", sans-serif;
    position: relative;
  }
  .expand-more {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    position: relative;
    overflow: visible;
  }
  .example {
    background: linear-gradient(
      64.97deg,
      rgba(227, 255, 199, 1) 0%,
      rgba(243, 255, 241, 1) 100%
    );
    border-radius: 8px;
    width: 910px;
    height: 695px;
    position: absolute;
    left: 478px;
    top: 161px;
    overflow: hidden;
  }
  .header2 {
    background: #ffffff;
    padding: 12px 24px 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 908px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .logo {
    display: flex;
    flex-direction: row;
    gap: 11px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .logo2 {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    position: static;
  }
  .rectangle-19 {
    background: #5d8069;
    border-radius: 8px;
    width: 44px;
    height: 44px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .rectangle-20 {
    background: #f8f8ff;
    border-radius: 24px;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .text {
    color: #5d8069;
    text-align: left;
    font: 500 18.26px/26.09px "Inter-Medium", sans-serif;
    position: relative;
  }
  .text2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 423px;
    position: absolute;
    left: 24px;
    top: 172px;
  }
  .txt {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 423px;
    position: relative;
  }
  .precision-karate-academy-master-the-art-of-discipline {
    flex-shrink: 0;
    width: 423px;
    height: 20px;
    position: relative;
  }
  .precision-karate-academy-master-the-art-of-discipline2 {
    color: #5d8069;
    text-align: left;
    font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
  }
  .precision-karate-academy-master-the-art-of-discipline3 {
    flex-shrink: 0;
    width: 423px;
    height: 90px;
    position: relative;
  }
  .precision-karate-academy-master-the-art-of-discipline4 {
    color: #000000;
    text-align: left;
    font: 600 32px/140% "Inter-SemiBold", sans-serif;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
  }
  .precision-karate-academy-master-the-art-of-discipline5 {
    color: #8f8f8f;
    text-align: left;
    font: 500 14px/20px "Inter-Medium", sans-serif;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 11.11%;
    top: 0%;
    height: 88.89%;
  }
  .precision-karate-academy-master-the-art-of-discipline6 {
    width: 423px;
    height: 34px;
    position: absolute;
    left: 24px;
    top: 553px;
  }
  .precision-karate-academy-master-the-art-of-discipline7 {
    color: #000000;
    text-align: left;
    font: 600 24px/140% "Inter-SemiBold", sans-serif;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
  }
  .action2 {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    left: 24px;
    top: 406px;
  }
  .frame-23 {
    background: #5d8069;
    border-radius: 4px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 96px;
    position: relative;
  }
  .book {
    color: #ffffff;
    text-align: left;
    font: 700 16px "Inter-Bold", sans-serif;
    position: relative;
  }
  .frame-242 {
    background: rgba(93, 128, 105, 0.16);
    border-radius: 4px;
    padding: 12px 16px 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
  }
  .learn-more {
    color: #5d8069;
    text-align: left;
    font: 700 16px "Inter-Bold", sans-serif;
    position: relative;
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 860px;
    position: absolute;
    left: 24px;
    top: 605px;
  }
  .rectangle-22 {
    background: #7db490;
    border-radius: 8px;
    flex: 1;
    height: 117px;
    position: relative;
  }
  .rectangle-23 {
    background: #7db490;
    border-radius: 8px;
    flex: 1;
    height: 117px;
    position: relative;
  }
  .rectangle-24 {
    background: #7db490;
    border-radius: 8px;
    flex: 1;
    height: 117px;
    position: relative;
  }
  .rectangle-25 {
    background: #7db490;
    border-radius: 8px;
    flex: 1;
    height: 117px;
    position: relative;
  }
  .image {
    width: 407px;
    height: 407px;
    position: absolute;
    left: 477px;
    top: 86px;
  }
  ._65-1 {
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
  }
  .chat {
    background: #ffffff;
    border-style: solid;
    border-color: #ebebeb;
    border-width: 0px 1px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 458px;
    height: 853px;
    position: absolute;
    left: 0px;
    top: 0px;
    box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.08),
      0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }
  .frame-27 {
    background: rgba(255, 255, 255, 0.85);
    border-style: solid;
    border-color: #ebebeb;
    border-width: 0px 0px 1px 0px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    flex-shrink: 0;
    height: 64px;
    position: relative;
    backdrop-filter: blur(8px);
  }
  .frame-272 {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .ellipse-10 {
    background: linear-gradient(to left, #626262, #626262);
    border-radius: 50%;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    position: relative;
  }
  .ai-builder {
    color: #000000;
    text-align: left;
    font: 600 16px "Montserrat-SemiBold", sans-serif;
    position: relative;
  }
  .remix-icons-line-system-question-line {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
  }
  .group3 {
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
  }
  .frame-19 {
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;
    flex: 1;
    position: relative;
  }
  .frame-43 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }
  .message-bubble {
    border-radius: 24px 2px 24px 24px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .frame-59 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.18);
  }
  .frame-8 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: auto;
    position: relative;
    overflow: visible;
  }
  .frame-6 {
    background: #f5f5f5;
    padding: 12px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    max-width: 500px;
    position: relative;
  }
  .frame-5 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    max-width: 500px;
    position: relative;
  }
  .write-your-message-here-3 {
    color: #000000;
    text-align: left;
    font: 400 16px "ProductSans-Regular", sans-serif;
    position: relative;
    align-self: stretch;
  }
  .frame-7 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }
  .rectangle-82 {
    background: #f5f5f5;
    border-radius: 0px 8px 0px 0px;
    flex-shrink: 0;
    width: 16px;
    height: 20px;
    position: relative;
  }
  .rectangle-112 {
    background: #f5f5f5;
    border-radius: 0px 0px 8px 0px;
    flex-shrink: 0;
    width: 16px;
    height: 20px;
    position: relative;
  }
  .frame-44 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }
  .message-bubble2 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
  }
  .frame-61 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.18);
  }
  .frame-72 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
  }
  .rectangle-83 {
    background: #c6d8ff;
    border-radius: 0px 8px 0px 0px;
    flex-shrink: 0;
    width: 16px;
    height: 20px;
    position: relative;
  }
  .rectangle-102 {
    background: #c6d8ff;
    flex: 1;
    width: 16px;
    position: relative;
  }
  .rectangle-113 {
    background: #c6d8ff;
    border-radius: 0px 0px 8px 0px;
    flex-shrink: 0;
    width: 16px;
    height: 20px;
    position: relative;
  }
  .frame-62 {
    background: #c6d8ff;
    padding: 12px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    max-width: 500px;
    position: relative;
    overflow: hidden;
  }
  .frame-9 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: auto;
    position: relative;
    transform: translate(-26px, 0px);
    overflow: visible;
  }
  .frame-45 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }
  .frame-60 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.18);
  }
  .frame-92 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: auto;
    position: relative;
    overflow: visible;
  }
  .frame-63 {
    background: #f5f5f5;
    padding: 12px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    max-width: 300px;
    position: relative;
  }
  .frame-52 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    max-width: 300px;
    position: relative;
  }
  .frame-49 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }
  .frame-68 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.18);
  }
  .frame-10 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(-1, 1);
  }
  .frame-64 {
    background: #c6d8ff;
    padding: 12px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    min-width: 150px;
    max-width: 500px;
    position: relative;
  }
  .frame-522 {
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 64px;
    position: relative;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16),
      0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }
  .frame-46 {
    background: #fefcf3;
    flex-shrink: 0;
    width: 32px;
    height: 64px;
    position: relative;
    overflow: hidden;
  }
  .frame-47 {
    background: #cbc5ba;
    flex-shrink: 0;
    width: 32px;
    height: 64px;
    position: relative;
    overflow: hidden;
  }
  .frame-48 {
    background: #3f3d39;
    flex-shrink: 0;
    width: 32px;
    height: 64px;
    position: relative;
    overflow: hidden;
  }
  .frame-492 {
    background: #252422;
    flex-shrink: 0;
    width: 32px;
    height: 64px;
    position: relative;
    overflow: hidden;
  }
  .frame-50 {
    background: #da6639;
    flex-shrink: 0;
    width: 32px;
    height: 64px;
    position: relative;
    overflow: hidden;
  }
  .frame-93 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: auto;
    position: relative;
    transform: translate(-26px, 0px);
    overflow: visible;
  }
  .frame-502 {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: flex-end;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }
  .frame-82 {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: auto;
    position: relative;
    overflow: visible;
  }
  .frame-18 {
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
  }
  .frame-3 {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    border-style: solid;
    border-color: #ebebeb;
    border-width: 1px;
    padding: 12px 12px 12px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }
  .frame-4 {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 490px;
    position: relative;
  }
  .line-1 {
    border-style: solid;
    border-color: #000000;
    border-width: 1px 0 0 0;
    flex-shrink: 0;
    width: 24px;
    height: 0px;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(90deg) scale(1, 1);
  }
  .describe-your-idea-in-once-sentence {
    color: #7f7f7f;
    text-align: left;
    font: 400 16px "ProductSans-Regular", sans-serif;
    position: relative;
  }
  .send {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    position: relative;
    overflow: visible;
  }

  
  