.button_panel {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.button_frame {
  background: #7654e8;
  border-radius: 8px;
  padding: 12px 40px 12px 40px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 43px;
  position: relative;
  cursor:pointer;
}
.button_text {
  color: #ffffff;
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}

.input_content {
  display: flex;
  flex-direction: column;
  gap: 1px;
  height: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin-top: 10px;
  position: relative;
  outline: none;
  font-size:medium;
}
.input_content:hover{
  outline: none;
 }

 .navlink {
  color: #2a79ff;
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  text-decoration: underline;
  position: relative;
  cursor: pointer;
}
      
.ai_builder {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 600 16px "Montserrat-SemiBold", sans-serif;
  position: relative;
 }
 .ai_builder:hover{
  cursor: pointer;
 }

      a,
      button,
      input,
      select,
      h1,
      h2,
      h3,
      h4,
      h5,
      * {
        margin: 0;
        padding: 0;
        border: none;
        text-decoration: none;
        appearance: none;
        background: none;

        -webkit-font-smoothing: antialiased;
      }
    
.dashboard,
.dashboard * {
  box-sizing: border-box;
}
.dashboard {
  background: var(--sufrace-surface-2, #fafafa);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.header {
  background: var(--sufrace-surface-1, #ffffff);
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 0px 0px 1px 0px;
  padding: 0px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100vw;
  height: 64px;
  position: relative;
}
.frame_98 {
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 798px;
  position: relative;
}
.frame_20 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_10 {
  background: linear-gradient(to left, #262626, #262626);
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}

.menu_items {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.meni_item_active {
  border-style: solid;
  border-color: var(--text-on-surface-accent, #9176ed);
  border-width: 0px 0px 2px 0px;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.dashboard2 {
  color: var(--text-on-surface-accent, #9176ed);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.menu_item {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.text {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_2608569 {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_2608568 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.language {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.english {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_96 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.ellipse_22 {
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.account {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.chevron_down {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.frame_281 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.nav_menu {
  background: var(--sufrace-surface-1, #ffffff);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  width: 342px;
  position: relative;
}
.menu {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.menu2 {
  border-radius: 8px;
  padding: 12px 14px 12px 14px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.remix_icons_line_system_arrow_left_line {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
}
.group {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.all_sites {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.frame_2608576 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sidemenu_item {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 310px;
  height: 44px;
  position: relative;
}
.frame_100 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.bank_card {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.title {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 16px "ProductSans-Regular", sans-serif;
  position: relative;
}
.sidemenu_item2 {
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 310px;
  height: 44px;
  position: relative;
}
.settings {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.content {
  padding: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame_303 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.frame_300 {
  background: var(--text-on-surface-accent, #9176ed);
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.all {
  color: var(--sufrace-surface-1, #ffffff);
  text-align: left;
  font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
}
.frame_301 {
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 0px 1px 0px 0px;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.published {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
}
.frame_302 {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.in_process {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: var(--textsm-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
}
.frame_278 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.search {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 10px 14px 10px 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 320px;
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.content2 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.remix_icons_line_system_search_line {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
}
.group2 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.text2 {
  color: var(--text-on-surface-secondary, #737373);
  text-align: left;
  font: 400 16px/24px "ProductSans-Regular", sans-serif;
  position: relative;
}
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.select {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 6px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 8px 12px 8px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 44px;
  position: relative;
}
.remix_icons_fill_system_menu_fill {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}
.group3 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.icon_chevron_down {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.select2 {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 6px;
  border-style: solid;
  border-color: var(--text-on-surface-accent, #9176ed);
  border-width: 1px;
  padding: 8px 12px 8px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  height: 44px;
  position: relative;
}
.remix_icons_line_system_filter_2_line {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}
.group4 {
  height: auto;
  position: absolute;
  right: 0%;
  left: 0%;
  width: 100%;
  bottom: 0%;
  top: 0%;
  height: 100%;
  overflow: visible;
}
.icon_chevron_down2 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  transform: translate(-16px, -16px);
  overflow: visible;
}
.select_options {
  border-radius: 6px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 204px;
  position: absolute;
  left: 0px;
  top: 48px;
  box-shadow: var(--shadow-box-shadow, 0px 4px 6px 0px rgba(0, 0, 0, 0.09));
  overflow: hidden;
}
.frame_3 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.top_section {
  background: var(--sufrace-surface-1, #ffffff);
  padding: 0px 5px 5px 5px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.menu_item2 {
  background: var(--sufrace-surface-1, #ffffff);
  padding: 6px 8px 6px 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.checkbox {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
}
.checkbox2 {
  border-radius: 2px;
  border-style: solid;
  border-color: var(--text-text-on-surface-secondary, #84868e);
  border-width: 2px;
  position: absolute;
  right: 12.5%;
  left: 12.5%;
  width: 75%;
  bottom: 12.5%;
  top: 12.5%;
  height: 75%;
}
.free_plan {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: var(--subtle-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
  flex: 1;
}
.pro_plan {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: var(--subtle-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
  flex: 1;
}
.checkbox3 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.ultimate_plan {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: var(--subtle-medium, 500 14px/20px "Inter-Medium", sans-serif);
  position: relative;
  flex: 1;
}
.button {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.plus {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.accept_all_cockies {
  color: var(--text-on-invert-primary, #ffffff);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.frame_283 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.all_projects {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 24px "ProductSans-Bold", sans-serif;
  position: relative;
}
.content3 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.new_card {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 12px;
  padding: 0px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.frame_309 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame_307 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.exapmle {
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 0px 0px 1px 0px;
  flex-shrink: 0;
  width: 328px;
  height: 200px;
  position: relative;
}
.frame_306 {
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame_315 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 235.5px;
  position: relative;
}
.frame_314 {
  background: var(--sufrace-surface-accent-subtle, rgba(118, 84, 232, 0.1));
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.basic_plan {
  color: var(--text-on-surface-accent, #9176ed);
  text-align: left;
  font: 700 12px "ProductSans-Bold", sans-serif;
  position: relative;
}
.button2 {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 32px;
  position: relative;
}
.line_01_up {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.accept_all_cockies2 {
  color: var(--text-on-invert-primary, #ffffff);
  text-align: left;
  font: 700 12px "ProductSans-Bold", sans-serif;
  position: relative;
}
.more {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.frame_312 {
  background: var(--text-on-invert-secondary, rgba(255, 255, 255, 0.7));
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: absolute;
  left: 16px;
  top: 159px;
  backdrop-filter: blur(36px);
}
.eye {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
._183_views {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 12px "ProductSans-Bold", sans-serif;
  position: relative;
}
.content4 {
  padding: 8px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.website_for_karate_school {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 700 16px "ProductSans-Bold", sans-serif;
  position: relative;
}
.www_karateschool_com {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.exapmle2 {
  border-radius: 8px;
  border-style: solid;
  border-color: var(--sufrace-border, #e5e5e5);
  border-width: 1px;
  flex-shrink: 0;
  width: 328px;
  height: 200px;
  position: relative;
}
.line_01_up2 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.more2 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.eye2 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.line_01_up3 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.more3 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.eye3 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.line_01_up4 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.more4 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.eye4 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.line_01_up5 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.more5 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.eye5 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.line_01_up6 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
.more6 {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.eye6 {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}
.help_resources {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  position: fixed; /*absolute*/
  /*left: 1316px;
  top: 769px;*/
  right: 20px;
  bottom: 20px;
  overflow: hidden;
}
.group_16 {
  position: absolute;
  inset: 0;
}
.overlay {
  background: var(--sufrace-surface-1, #ffffff);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -78px;
  top: -44px;
}
.help_resources2 {
  color: var(--text-on-surface-primary, #262626);
  text-align: left;
  font: 400 14px "ProductSans-Regular", sans-serif;
  position: relative;
}
.polygon_7 {
  border-radius: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 30px;
  top: -4px;
  overflow: visible;
}
.button3 {
  background: var(--button-primary-primary-default, #7654e8);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 22px);
  top: calc(50% - 22px);
}
.question_mark_cr_fr {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible;
}
